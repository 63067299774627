import { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import CompTable from '../../images/CompTable-new.jpeg';
import pricing from '../../images/pricingnew.jpeg';
import searchengine from '../../images/searchengine-new.jpeg';

function FeaturesA() {
    return (
        <div style={{ padding: '40px' }}>
            <h1 style={{ fontWeight: 'bold', textAlign: 'center', paddingBottom: '40px' }}>
                Every tool you need to make those key wedding decisions
            </h1>
            <Container>
                <Row>
                    <Col sm={6}>
                        <h2 style={{ fontWeight: 'bold', color: "#32969C" }}>
                            Easily Search and Filter For Your Favourite Vendors
                        </h2>
                        <h3>Use intuitive filtering capabilities to search for your favourite vendors and be presented with their pricing, ratings, and all the information you will need!</h3>
                    </Col>
                    <Col sm={6}><img style={imgStyle} src={searchengine}></img></Col>
                </Row>
                <Row style={{ paddingTop: '50px' }}>
                    <Col sm={6}>
                        <h2 style={{ fontWeight: 'bold', color: "#32969C" }}>
                            Say Goodbye To Decision Fatigue
                        </h2>
                        <h3>Save time making decisions by easily comparing vendors with Zenplan's generated comparison tables that are based on your personal requirements and vision!</h3>
                    </Col>
                    <Col sm={6}><img style={imgStyle} src={CompTable}></img></Col>
                </Row>
                <Row style={{ paddingTop: '50px' }}>
                    <Col sm={6}>
                        <h2 style={{ fontWeight: 'bold', color: "#32969C" }}>
                            Transparent Pricing
                        </h2>
                        <h3>Say hello to clarity and peace of mind with transparent pricing from user reported submissions or direct vendor uploaded information!</h3>
                    </Col>
                    <Col sm={6}><img style={imgStyle} src={pricing}></img></Col>
                </Row>
            </Container>
        </div>
    );
}

const imgStyle = {
    width: '100%',
    boxShadow: '5px 5px 5px 5px #ccc',
    borderRadius: '10px'
};

export default FeaturesA;