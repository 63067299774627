import React, { useState } from 'react';
import logo from '../images/logo.png';
import { MDBFooter, MDBContainer, MDBRow, MDBCol, MDBIcon } from 'mdb-react-ui-kit';
import { FaTiktok } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { FaInstagram } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { useMediaQuery } from 'react-responsive';
import { Footer } from "flowbite-react";

export default function Bottom() {
  const isMobile = useMediaQuery({ maxWidth: 984 }); // Define your mobile screen width here
  return (
    <div>
      <Footer container className='bg-zenplanColour text-white' style={{ borderRadius: '0' }}>
        <div className={`container mx-auto px-4 ${isMobile ? 'text-center' : ''}`}>
          <div className={`flex ${isMobile ? 'flex-col' : 'flex-row'} items-center justify-center`}>
            {/* Logo Column */}
            <div className={`mb-3 ${isMobile ? '' : 'mr-8'}`}>
              <img src={logo} alt="Logo" className="w-40" />
            </div>

            {/* Social Media Icons and Contact Info Column */}
            <div className={`flex ${isMobile ? 'flex-col items-center' : 'flex-row'} justify-center`}>
              {/* Social Media Icons */}
              <div className={`flex ${isMobile ? 'mb-3' : 'mr-8'}`}>
                <a className="text-white text-3xl mx-2 hover:text-gray-300" href='https://www.instagram.com/zenplanig/'>
                  <FaInstagram size={25} />
                </a>
                <a className="text-white text-3xl mx-2 hover:text-gray-300" href='https://www.tiktok.com/@zenplantiktok'>
                  <FaTiktok size={25} />
                </a>
                <a className="text-white text-3xl mx-2 hover:text-gray-300" href='https://www.youtube.com/channel/UCcLi8QlBGERKrsOd8lwNPlA'>
                  <FaYoutube size={25} />
                </a>
              </div>

              {/* Contact Info */}
              <div className={`flex items-center ${isMobile ? 'mb-3' : ''}`}>
                <MdEmail size={25} className="text-xl mr-2" />
                <span className="text-xl">{"contact@zenplan.ca"}</span>
              </div>
            </div>
          </div>
          <Footer.Divider />
          <Footer.Copyright style={{ color: 'white' }} by="Zenplan" year={2024} />
        </div>
      </Footer>
    </div>
  );
}