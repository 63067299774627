import React, { useState, useMemo, useEffect, useContext } from 'react';
import { useReactTable, getCoreRowModel, flexRender, getPaginationRowModel, getSortedRowModel, getFilteredRowModel } from '@tanstack/react-table';
import { Link } from "react-router-dom";
import StarRatings from "react-star-ratings";
import Container from 'react-bootstrap/Container';
import { AiOutlineHeart, AiFillHeart } from 'react-icons/ai';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useMediaQuery } from 'react-responsive';
import { DebounceInput } from 'react-debounce-input';
import axios from 'axios';
import { TfiViewListAlt } from "react-icons/tfi";
import { MdOutlineGridView } from "react-icons/md";
import { Button, Card } from "flowbite-react";
import Dropdown from 'react-bootstrap/Dropdown';
import ClipLoader from "react-spinners/ClipLoader";
import NavbarB from './planning_variant/navbar_b.jsx';
import ReactGA from "react-ga4";
import Bottom from './bottom.jsx';
import { AccountContext } from "./Account";
import UserPool from '../UserPool';
import { useNavigate } from 'react-router-dom';

function Photographers() {
    const [records, setRecords] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [viewMode, setViewMode] = useState('grid'); // State for tracking view mode
    const [globalFilter, setGlobalFilter] = useState('');
    const [sorting, setSorting] = useState([]);
    const [columnFilters, setColumnFilters] = useState([]);
    const [selectedSortOption, setSelectedSortOption] = useState('Select Column');
    const [favourites, setFavourites] = useState(new Set());  // State for managing user favourites
    const { getSession } = useContext(AccountContext);
    const [userID, setUserID] = useState(null);
    const navigate = useNavigate(); 

    ReactGA.send({ hitType: "pageview", page: "/photographers", title: "Browse Photographers Visit" });

    const getRecords = () => {
        axios.get('https://nqnct4n678.execute-api.us-east-1.amazonaws.com/items')
            .then(resp => {
                console.log('calling api')
                setRecords(resp.data)
            })
            .catch(err => {
                setErrorMessage(err);
                console.log(err);
            })
    };

    React.useEffect(() => getRecords(), []);

    const isMobile = useMediaQuery({ maxWidth: 984 }); // Define your mobile screen width here
    const data = useMemo(() => records ?? [], [records])

    useEffect(() => {
        console.log('here');
        if (UserPool.getCurrentUser() == null) {
            setUserID(null);
        } else {
            getSession().then(({ user }) => {
                setUserID(String(user.username));
            });
        }
    }, [getSession]);

    useEffect(() => {
        if (userID) {
            axios.get(`https://l1279w6urd.execute-api.us-east-1.amazonaws.com/favourites?userID=${userID}`)
                .then(response => {
                    setFavourites(new Set(response.data.photoFavs));
                })
                .catch(error => {
                    console.error('Error fetching favorites:', error);
                });
        }
    }, [userID]);

    const handleFavouriteClick = (vendorId) => {
        if (!userID) {
            // Redirect to login page if user is not logged in
            navigate('/login');
            return;
        }

        const updatedFavourites = new Set(favourites);
        const action = updatedFavourites.has(vendorId) ? 'remove' : 'add';

        if (action === 'remove') {
            updatedFavourites.delete(vendorId);
        } else {
            updatedFavourites.add(vendorId);
        }
        setFavourites(updatedFavourites);

        try {
            // Call API to save the entire updated favorites list to the database
            axios.post('https://l1279w6urd.execute-api.us-east-1.amazonaws.com/favourites', {
                userID,
                updatedFavourites: Array.from(updatedFavourites),
            });
            console.log(`Favorite updated successfully.`);
        } catch (error) {
            console.error('Error updating favorites:', error);
        }
    };

    function filterPrice(row, columnId, filterValue) {
        let test = row.original.starting_price <= filterValue
        return test ? true : false
    }

    const columns = [
        {
            header: 'Image',
            accessorKey: 's3_path',
            footer: 'Image',
            cell: (info) => {
                return (
                    <img src={`https://d21i5s43fw3pjx.cloudfront.net/${info.getValue()[0]}`} alt="image" style={{ width: '250px', height: '250px', boxShadow: '3px 3px 3px 3px #ccc', borderRadius: '10px' }}></img>
                )
            },
            enableSorting: false
        },
        {
            header: 'Name',
            accessorKey: 'name',
            footer: 'Name'
        },
        {
            header: 'Location',
            accessorKey: 'location',
            footer: 'Location'
        },
        {
            header: 'Starting Price',
            accessorKey: 'starting_price',
            footer: 'Starting Price',
            cell: (info) => {
                return (
                    <div>
                        {isMobile ?
                            (
                                <div>
                                    <strong>Starting Price: </strong>{"$" + info.getValue()}
                                </div>
                            ) :
                            (
                                <div>
                                    {"$" + info.getValue()}
                                </div>
                            )
                        }
                    </div>
                )
            },
            filterFn: filterPrice
        },
        {
            header: 'Photo or Video Services',
            accessorKey: 'photography_or_video_services',
            footer: 'Photo or Video Services',
            enableSorting: false
        },
        {
            header: 'Rating',
            accessorKey: 'review_rating',
            footer: 'Rating',
            cell: ({ row }) => {
                const rowData = row.original

                return (
                    <div>
                        <div style={{ color: '#3FBAC2' }}>{rowData.review_rating} Stars</div>
                        <StarRatings
                            rating={rowData.review_rating}
                            starRatedColor="#3FBAC2"
                            numberOfStars={5}
                            name='rating'
                            starDimension="15px"
                            starSpacing='1px'
                        />
                        <br></br>
                        <div style={{ color: '#3FBAC2' }}>{rowData.number_of_reviews} Reviews</div>
                    </div>
                );
            }
        },
        {
            id: "actions",
            cell: ({ row }) => {
                const rowData = row.original

                return (
                    <button className="btn btn-block" style={buttonStyle}><Link style={{ color: 'white', textDecoration: 'none' }} to={`/photographers/${rowData.id}`} state={rowData}>Vendor Details</Link></button>
                )
            }
        }
    ]

    const table = useReactTable({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        state: {
            sorting,
            columnFilters,
            globalFilter,
        },
        onSortingChange: setSorting,
        onColumnFiltersChange: setColumnFilters,
        onGlobalFilterChange: setGlobalFilter,
        initialState: {
            pagination: {
                pageSize: 9,
            },
        },
    });

    const handleSortChange = (columnId, direction) => {
        const labels = {
            starting_price: 'Price',
            review_rating: 'Rating',
        };

        if (columnId === 'starting_price' || columnId === 'review_rating') {
            setSorting([{ id: columnId, desc: direction === 'desc' }]);
            setSelectedSortOption(labels[columnId] || 'Select Column');
        }
    };

    return (
        <div>
            <NavbarB />
            <div style={{ height: '100%', width: '100%' }}>
                <div>
                    {isMobile ?
                        (
                            <div className='container' style={{ paddingTop: '15px', paddingBottom: '15px' }}>
                                <div style={{ height: 'auto', justifyContent: 'center', alignItems: 'center', textAlign: 'center', margin: '0 auto' }}>
                                    <h1 className='col-mb-3' style={{ fontWeight: 'bold', fontSize: 30, paddingBottom: '2px' }}>Find Photographer Prices</h1>
                                    <h3 className='col-mb-3' style={{ fontWeight: 'bold', fontSize: 20, paddingBottom: '10px' }}>More photographers are being added every week!</h3>
                                </div>
                            </div>
                        ) :
                        (
                            <div className='container' style={{ paddingTop: '15px', paddingBottom: '15px' }}>
                                <div style={{ height: 'auto', justifyContent: 'center', alignItems: 'center', textAlign: 'center', margin: '0 auto' }}>
                                    <h1 className='col-mb-3' style={{ fontWeight: 'bold', fontSize: 45, paddingBottom: '2px' }}>Find Photographer Prices</h1>
                                    <h3 className='col-mb-3' style={{ fontWeight: 'bold', fontSize: 25, paddingBottom: '10px' }}>More photographers are being added every week!</h3>
                                </div>
                            </div>
                        )
                    }
                    <div className='container' style={{ padding: '5px' }}>
                        <div style={{ height: 'auto', justifyContent: 'center', alignItems: 'center', textAlign: 'center', margin: '0 auto' }}>
                            <div style={{ backgroundColor: '#ffffff', boxShadow: '2px 2px 2px 2px #eee', borderRadius: '10px' }} className="flex items-center py-4">
                                <Container>
                                    <Row>
                                        <Col lg={3}>
                                            <label style={{ fontSize: 18, marginRight: '3px', marginLeft: '10px' }}>Name: </label>
                                            <br></br>
                                            <DebounceInput
                                                placeholder="Search name"
                                                value={(table.getColumn("name")?.getFilterValue()) ?? ""}
                                                onChange={(event) =>
                                                    table.getColumn("name")?.setFilterValue(event.target.value)
                                                }
                                                className="max-w-sm"
                                                style={inputStyle}
                                                debounceTimeout={500}
                                                list="name"
                                            />
                                            <datalist id="name">
                                                {[...new Set(data.map(record => record.name))].map((item) => (
                                                    <option value={item}>{item}</option>
                                                ))}
                                            </datalist>
                                        </Col>
                                        <Col lg={3}>
                                            <label style={{ fontSize: 18, marginRight: '3px', marginLeft: '10px' }}>Location: </label>
                                            <br></br>
                                            <DebounceInput
                                                placeholder="Filter location"
                                                value={(table.getColumn("location")?.getFilterValue()) ?? ""}
                                                onChange={(event) =>
                                                    table.getColumn("location")?.setFilterValue(event.target.value)
                                                }
                                                className="max-w-sm"
                                                style={inputStyle}
                                                debounceTimeout={500}
                                                list="locations"
                                            />
                                            <datalist id="locations">
                                                {[...new Set(data.map(record => record.location))].map((item) => (
                                                    <option value={item}>{item}</option>
                                                ))}
                                            </datalist>
                                        </Col>

                                        <Col lg={3}>
                                            <label style={{ fontSize: 18, marginRight: '3px', marginLeft: '10px' }}>Max Price: </label>
                                            <br></br>
                                            <DebounceInput
                                                placeholder="Enter max price"
                                                value={(table.getColumn("starting_price")?.getFilterValue()) ?? ""}
                                                onChange={(event) =>
                                                    table.getColumn("starting_price")?.setFilterValue(event.target.value)
                                                }
                                                className="max-w-sm"
                                                style={inputStyle}
                                                debounceTimeout={500}
                                            />
                                        </Col>
                                    </Row>
                                </Container>
                            </div>

                            {!isMobile ? (
                                <div className="flex justify-between mb-4">
                                    <div className="flex items-center space-x-4">
                                        Sort By:
                                        <Dropdown onSelect={(key) => handleSortChange(key, 'asc')} style={{ marginLeft: '5px' }}>
                                            <Dropdown.Toggle id="dropdown-basic" style={{ backgroundColor: "#3FBAC2", borderColor: "#3FBAC2" }}>
                                                {selectedSortOption || 'Sort By'}
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item eventKey="starting_price">Price</Dropdown.Item>
                                                <Dropdown.Item eventKey="review_rating">Rating</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>

                                        <Dropdown onSelect={(key) => handleSortChange(sorting[0]?.id, key)}>
                                            <Dropdown.Toggle id="dropdown-order" style={{ backgroundColor: "#3FBAC2", borderColor: "#3FBAC2" }}>
                                                {sorting[0]?.desc ? 'Desc' : 'Asc'}
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item eventKey="asc">Asc</Dropdown.Item>
                                                <Dropdown.Item eventKey="desc">Desc</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>

                                    <div className="mt-2 mb-4 flex justify-end">
                                        <button className={`btn ${viewMode === 'grid' ? 'active' : ''}`} style={{ ...viewMode === 'grid' ? activeButtonStyle : inactiveButtonStyle, display: 'flex', alignItems: 'center', gap: '2px' }} onClick={() => setViewMode('grid')}>
                                            <MdOutlineGridView /> Grid
                                        </button>
                                        <button className={`btn ${viewMode === 'list' ? 'active' : ''}`} style={{ ...viewMode === 'list' ? activeButtonStyle : inactiveButtonStyle, display: 'flex', alignItems: 'center', gap: '2px' }} onClick={() => setViewMode('list')}>
                                            <TfiViewListAlt /> List
                                        </button>
                                    </div>
                                </div>
                            ) : (
                                <div className="flex justify-between mb-4" style={{ marginTop: '5px' }}>
                                    <div className="flex items-center space-x-4">
                                        Sort By:
                                        <Dropdown onSelect={(key) => handleSortChange(key, 'asc')} style={{ marginLeft: '5px' }}>
                                            <Dropdown.Toggle id="dropdown-basic" style={{ backgroundColor: "#3FBAC2", borderColor: "#3FBAC2" }}>
                                                {selectedSortOption || 'Sort By'}
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item eventKey="starting_price">Price</Dropdown.Item>
                                                <Dropdown.Item eventKey="review_rating">Rating</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>

                                        <Dropdown onSelect={(key) => handleSortChange(sorting[0]?.id, key)}>
                                            <Dropdown.Toggle id="dropdown-order" style={{ backgroundColor: "#3FBAC2", borderColor: "#3FBAC2" }}>
                                                {sorting[0]?.desc ? 'Desc' : 'Asc'}
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item eventKey="asc">Asc</Dropdown.Item>
                                                <Dropdown.Item eventKey="desc">Desc</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </div>
                            )}

                            {isMobile ? (
                                data.length === 0 ? (
                                    <ClipLoader color="#32969C" size={75} />
                                ) : (
                                    <div className="mt-2 grid grid-cols-1 gap-4 justify-items-center">
                                        {table.getRowModel().rows.map(row => {
                                            const rowData = row.original;
                                            const isFavourite = favourites.has(rowData.id);
                                            return (
                                                <Card key={row.id} className="max-w-sm w-full flex flex-col items-center">
                                                    <div className="relative w-full">
                                                        <img src={`https://d21i5s43fw3pjx.cloudfront.net/${rowData.s3_path}`} alt="photographer" className="w-full h-auto object-cover" />
                                                        <button onClick={() => handleFavouriteClick(rowData.id)} className="absolute top-2 right-2 p-1 bg-white rounded-full" style={{ backgroundColor: 'rgba(255, 255, 255, 1)' }}>
                                                            {isFavourite ? <AiFillHeart color="#3FBAC2" size={24} /> : <AiOutlineHeart color="#3FBAC2" size={24} />}
                                                        </button>
                                                    </div>
                                                    <div className="p-2 flex flex-col items-start">
                                                        <h5 className="font-bold tracking-tight text-gray-900 dark:text-white text-left mb-2" style={{ fontsize: 20 }}>
                                                            {rowData.name}
                                                        </h5>
                                                        <p className="font-normal text-gray-700 dark:text-gray-400 text-left mb-2">
                                                            {rowData.location}
                                                        </p>
                                                        <p className="font-normal text-gray-700 dark:text-gray-400 text-left mb-2">
                                                            {rowData.review_rating}
                                                            <StarRatings
                                                                rating={rowData.review_rating}
                                                                starRatedColor="#3FBAC2"
                                                                numberOfStars={5}
                                                                name='rating'
                                                                starDimension="15px"
                                                                starSpacing='1px'
                                                            />
                                                            {' '}({rowData.number_of_reviews} reviews)
                                                        </p>
                                                        <p className="font-normal text-gray-700 dark:text-gray-400 text-left mb-2">
                                                            <strong>Starting Price: </strong>{"$" + rowData.starting_price}
                                                        </p>
                                                        <p className="font-normal text-gray-700 dark:text-gray-400 text-left">
                                                            <strong>Photography or Video Services: </strong>{rowData.photography_or_video_services}
                                                        </p>
                                                        <Button className="mt-4 bg-zenplanColour">
                                                            <Link style={{ color: 'white', textDecoration: 'none' }} to={`/photographers/${rowData.id}`} state={rowData}>
                                                                Vendor Details
                                                            </Link>
                                                        </Button>
                                                    </div>
                                                </Card>
                                            );
                                        })}
                                    </div>
                                )
                            ) : (
                                viewMode === 'grid' ? (
                                    data.length === 0 ? (
                                        <ClipLoader color="#32969C" size={75} />
                                    ) : (
                                        <div className="grid grid-cols-3 gap-4">
                                            {table.getRowModel().rows.map(row => {
                                                const rowData = row.original;
                                                const isFavourite = favourites.has(rowData.id);
                                                return (
                                                    <Card key={row.id} className="max-w-sm flex flex-col overflow-hidden p-0" style={{ padding: '0' }}>
                                                        <div className="relative w-full h-[300px]">
                                                            <img src={`https://d21i5s43fw3pjx.cloudfront.net/${rowData.s3_path}`} alt="photographer" className="w-full" style={{ height: '300px', objectFit: 'cover', display: 'block', borderRadius: '3px' }} />
                                                            <button onClick={() => handleFavouriteClick(rowData.id)} className="absolute top-1 right-1 p-1 rounded-full" style={{ backgroundColor: 'rgba(255, 255, 255, 1)' }} >
                                                                {isFavourite ? <AiFillHeart color="#3FBAC2" size={25} /> : <AiOutlineHeart color="#3FBAC2" size={25} />}
                                                            </button>
                                                        </div>
                                                        <div className="flex-grow flex flex-col justify-start">
                                                            <h5 className="text-xl font-bold tracking-tight text-gray-900 dark:text-white text-left m-0 mb-1">
                                                                {rowData.name}
                                                            </h5>
                                                            <p className="font-normal text-gray-700 dark:text-gray-400 text-left m-0 mb-1">
                                                                {rowData.location}
                                                            </p>
                                                            <p className="font-normal text-gray-700 dark:text-gray-400 text-left m-0 mb-1">
                                                                {rowData.review_rating}
                                                                <StarRatings
                                                                    rating={rowData.review_rating}
                                                                    starRatedColor="#3FBAC2"
                                                                    numberOfStars={5}
                                                                    name="rating"
                                                                    starDimension="15px"
                                                                    starSpacing="1px"
                                                                /> ({rowData.number_of_reviews} reviews)
                                                            </p>
                                                            <p className="font-normal text-gray-700 dark:text-gray-400 text-left m-0 mb-1">
                                                                <strong>Starting Price: </strong>{"$" + rowData.starting_price}
                                                            </p>
                                                            <p className="font-normal text-gray-700 dark:text-gray-400 text-left">
                                                                <strong>Photography or Video Services: </strong>{rowData.photography_or_video_services}
                                                            </p>
                                                            <div className="mt-auto">
                                                                <Button className="mt-4 bg-zenplanColour">
                                                                    <Link style={{ color: 'white', textDecoration: 'none' }} to={`/photographers/${rowData.id}`} state={rowData}>
                                                                        Vendor Details
                                                                    </Link>
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    </Card>
                                                );
                                            })}
                                        </div>
                                    )
                                ) : (
                                    data.length === 0 ? (
                                        <ClipLoader color="#32969C" size={75} />
                                    ) : (
                                        <div className="grid grid-cols-1 gap-4">
                                            {table.getRowModel().rows.map(row => {
                                                const rowData = row.original;
                                                const isFavourite = favourites.has(rowData.id);
                                                return (
                                                    <div key={row.id} className="flex flex-row w-full items-center bg-white shadow-md rounded-lg overflow-hidden border border-gray-200">
                                                        <div className="w-1/3 flex items-center relative">
                                                            <img src={`https://d21i5s43fw3pjx.cloudfront.net/${rowData.s3_path}`} alt="photographer" className="w-full object-cover" style={{ margin: '5px', borderRadius: '3px', height: '300px' }} />
                                                            <button onClick={() => handleFavouriteClick(rowData.id)} className="absolute top-2 right-1 p-1 rounded-full" style={{ backgroundColor: 'rgba(255, 255, 255, 1)' }} >
                                                                {isFavourite ? <AiFillHeart color="#3FBAC2" size={25} /> : <AiOutlineHeart color="#3FBAC2" size={25} />}
                                                            </button>
                                                        </div>
                                                        <div className="p-4 w-2/3">
                                                            <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white text-left">
                                                                {rowData.name}
                                                            </h5>
                                                            <p className="font-normal text-gray-700 dark:text-gray-400 text-left">
                                                                {rowData.review_rating}
                                                                <StarRatings
                                                                    rating={rowData.review_rating}
                                                                    starRatedColor="#3FBAC2"
                                                                    numberOfStars={5}
                                                                    name="rating"
                                                                    starDimension="15px"
                                                                    starSpacing="1px"
                                                                /> ({rowData.number_of_reviews} reviews) | {rowData.location}
                                                            </p>
                                                            <p className="font-normal text-gray-700 dark:text-gray-400 text-left">
                                                                <strong>Starting Price: </strong>
                                                                {"$" + rowData.starting_price}
                                                            </p>
                                                            <p className="font-normal text-gray-700 dark:text-gray-400 text-left">
                                                                <strong>Photography or Video Services: </strong>{rowData.photography_or_video_services}
                                                            </p>
                                                            <Button className="mt-4 bg-zenplanColour">
                                                                <Link style={{ color: 'white', textDecoration: 'none' }} to={`/photographers/${rowData.id}`} state={rowData}>
                                                                    Vendor Details
                                                                </Link>
                                                            </Button>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    )
                                )
                            )}

                            <div className='mt-2 mb-4'>
                                <button className="btn btn-block" style={paginationButtonStyle} disabled={!table.getCanPreviousPage()} onClick={() => table.previousPage()}>
                                    Previous
                                </button>
                                <button className="btn btn-block" style={paginationButtonStyle} disabled={!table.getCanNextPage()} onClick={() => table.nextPage()}>
                                    Next
                                </button>
                                <span className="flex items-center justify-center gap-1">
                                    <div style={{ marginTop: '5px' }}>Page
                                        <strong style={{ marginLeft: '5px' }}>
                                            {table.getState().pagination.pageIndex + 1} of{' '}
                                            {table.getPageCount()}
                                        </strong>
                                    </div>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Bottom />
        </div>
    );
}

export default Photographers;

const tableStyle = {
    alignItems: 'center',
    textAlign: 'center',
    marginTop: '10px',
    boxShadow: '3px 3px 3px 3px #ccc'
};

const tableHeadStyle = {
    marginTop: '10px',
    backgroundColor: "#3FBAC2",
    color: 'white',
    fontWeight: 'bold',
    fontSize: 20
};

const tableRowStyle = {
    fontSize: 18
};

const paginationButtonStyle = {
    backgroundColor: "#32969C",
    color: 'white',
    fontWeight: 'bold',
    fontSize: 15,
    marginRight: '5px',
    fontFamily: 'Raleway'
};

const buttonStyle = {
    backgroundColor: "#32969C",
    color: 'white',
    fontWeight: 'bold',
    fontSize: 15,
    fontFamily: 'Raleway'
};

const inputStyle = {
    border: '1px solid',
    borderRadius: '5px',
    padding: '5px',
    backgroundColor: '#ededed'
};

const activeButtonStyle = {
    backgroundColor: "#3FBAC2",
    color: 'white',
    fontWeight: 'bold',
    fontSize: 15,
    margin: '0 10px',
    border: '1px solid #3FBAC2',
    borderRadius: '5px',
    padding: '5px 15px'
};

const inactiveButtonStyle = {
    backgroundColor: "white",
    color: '#3FBAC2',
    fontWeight: 'bold',
    fontSize: 15,
    margin: '0 10px',
    border: '1px solid #3FBAC2',
    borderRadius: '5px',
    padding: '5px 15px'
};
