import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive';

export default function WaitlistB() {
    const isMobile = useMediaQuery({ maxWidth: 984 }); // Define your mobile screen width here
    return (
        <div>
            {isMobile ?
                (
                    <div style={{backgroundColor: "#3FBAC2", color: 'white', align: 'center', textAlign: 'center' }}>
                        <div className='container'>
                            <div className='justify-content-center align-items-center'>
                                <h1 className='col-mb-3' style={{ paddingTop: '20px', fontWeight: 'bold', fontSize: 30 }}>
                                    Are you a Wedding Vendor Interested In Partnering With Zenplan?
                                </h1>
                                <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                                    <h4 style={{ paddingTop: '20px', paddingBottom: '20px', align: 'center', textAlign: 'center' }}>
                                        <a href='/vendor_waitlist'>
                                            <button className="btn btn-block" style={buttonStyle}>
                                                Join Vendor Waitlist
                                            </button>
                                        </a>
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </div>
                ) :
                (
                    <div style={{ padding: '50px', backgroundColor: "#3FBAC2", color: 'white', align: 'center', textAlign: 'center' }}>
                        <div className='container'>
                            <div className='justify-content-center align-items-center'>
                                <h1 className='col-mb-3' style={{ fontWeight: 'bold', fontSize: 50 }}>Are you a Wedding Vendor Interested<br></br> In Partnering With Zenplan?</h1>
                                <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                                    {/* <h4 style={{ paddingBottom: '20px', align: 'center', textAlign: 'center', marginRight: '50px' }}>
                                <a href='/couples_waitlist'>
                                    <button className="btn btn-block" style={buttonStyle}>
                                        Are you getting married?
                                    </button>
                                </a>
                            </h4> */}
                                    <h4 style={{ paddingBottom: '20px', align: 'center', textAlign: 'center' }}>
                                        <a href='/vendor_waitlist'>
                                            <button className="btn btn-block" style={buttonStyle}>
                                                Join Vendor Waitlist
                                            </button>
                                        </a>
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        </div>
    );
}

const buttonStyle = {
    backgroundColor: "#32969C",
    color: 'white',
    fontWeight: 'bold',
    fontSize: 25
};
