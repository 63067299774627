import React, { useState, useEffect, useRef, useContext } from "react";
import { AccountContext } from "../Account.js";
import logo from '../../images/logonew.png';
import { MdAccountCircle } from "react-icons/md";

const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false); // For mobile menu toggle
    const [activeDropdown, setActiveDropdown] = useState(""); // To manage active dropdown on click
    const dropdownRefs = useRef({}); // Store refs for each dropdown

    const [status, setStatus] = useState(false);
    const { getSession, logout } = useContext(AccountContext);

    useEffect(() => {
        getSession()
            .then(session => {
                console.log("Session: ", session)
                setStatus(true);
            })
    }, []);

    // Function to toggle dropdown on click
    const handleDropdownClick = (name) => {
        setActiveDropdown(activeDropdown === name ? "" : name);
    };

    // Close dropdown if clicked outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                activeDropdown &&
                dropdownRefs.current[activeDropdown] &&
                !dropdownRefs.current[activeDropdown].contains(event.target)
            ) {
                setActiveDropdown(""); // Close the active dropdown
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [activeDropdown]);

    return (
        <nav className="bg-white border-b border-gray-200">
            <div className="container mx-auto px-4 sm:px-6 lg:px-8">
                <div className="flex items-center justify-between h-16">
                    <div className="flex items-center">
                        <a href='/'><img style={{ width: '120px' }} src={logo}></img></a>
                    </div>

                    {/* Desktop Menu */}
                    {status ? (
                        <>
                            <div className="hidden md:flex space-x-8">
                                <a href="/venues" className="text-[#3FBAC2] hover:text-[#339B9F] no-underline font-bold">Venues</a>

                                {/* Vendors Dropdown */}
                                <div className="relative" ref={(el) => (dropdownRefs.current["vendors"] = el)}>
                                    <button onClick={() => handleDropdownClick("vendors")} className="text-[#3FBAC2] hover:text-[#339B9F] flex items-center font-bold">
                                        Vendors
                                        <svg className="ml-1 w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                                        </svg>
                                    </button>
                                    {activeDropdown === "vendors" && (
                                        <div className="absolute left-0 mt-2 w-48 bg-white border rounded shadow-lg z-50">
                                            <a href="/photographers" className="block px-4 py-2 text-[#3FBAC2] hover:bg-gray-100 no-underline font-bold">Photographers</a>
                                            <a href="/djs" className="block px-4 py-2 text-[#3FBAC2] hover:bg-gray-100 no-underline font-bold">DJs</a>
                                        </div>
                                    )}
                                </div>

                                <a href="/favourites" className="text-[#3FBAC2] hover:text-[#339B9F] no-underline font-bold">Favourites</a>

                                <a href="/vendor_waitlist" className="text-[#3FBAC2] hover:text-[#339B9F] no-underline font-bold">Join Vendor Waitlist</a>
                                <a href="/comingsoon" className="text-[#3FBAC2] hover:text-[#339B9F] no-underline font-bold">Upcoming Features</a>
                            </div>

                            <div className="hidden md:flex items-center space-x-4">
                                <button className="px-3 py-1 text-sm text-white bg-[#3FBAC2] rounded hover:bg-[#339B9F] flex items-center space-x-2">
                                    <MdAccountCircle size={20} />
                                    <a href="/profile" className="text-white hover:text-[#339B9F] no-underline font-bold">Profile</a>
                                </button>
                            </div>

                        </>
                    ) : (
                        <>
                            <div className="hidden md:flex space-x-8">
                                <a href="/venues" className="text-[#3FBAC2] hover:text-[#339B9F] no-underline font-bold">Venues</a>

                                {/* Vendors Dropdown */}
                                <div className="relative" ref={(el) => (dropdownRefs.current["vendors"] = el)}>
                                    <button onClick={() => handleDropdownClick("vendors")} className="text-[#3FBAC2] hover:text-[#339B9F] flex items-center font-bold">
                                        Vendors
                                        <svg className="ml-1 w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                                        </svg>
                                    </button>
                                    {activeDropdown === "vendors" && (
                                        <div className="absolute left-0 mt-2 w-48 bg-white border rounded shadow-lg z-50">
                                            <a href="/photographers" className="block px-4 py-2 text-[#3FBAC2] hover:bg-gray-100 no-underline font-bold">Photographers</a>
                                            <a href="/djs" className="block px-4 py-2 text-[#3FBAC2] hover:bg-gray-100 no-underline font-bold">DJs</a>
                                        </div>
                                    )}
                                </div>

                                <a href="/vendor_waitlist" className="text-[#3FBAC2] hover:text-[#339B9F] no-underline font-bold">Join Vendor Waitlist</a>
                            </div>

                            <div className="hidden md:flex items-center space-x-4">
                                <button className="px-3 py-1 text-sm text-white bg-[#3FBAC2] rounded hover:bg-[#339B9F]"><a href="/login" className="text-white hover:text-[#339B9F] no-underline font-bold">Login</a></button>
                            </div>
                        </>
                    )}


                    {/* Mobile Menu Button */}
                    <div className="flex md:hidden">
                        <button onClick={() => setIsOpen(!isOpen)} className="text-[#3FBAC2] focus:outline-none">
                            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d={isOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16m-7 6h7"} />
                            </svg>
                        </button>
                    </div>
                </div>
            </div>

            {/* Mobile Menu */}
            {isOpen && (
                <div className="md:hidden px-2 pt-2 pb-3 space-y-1 sm:px-3">
                    {status ? (
                        <>
                            <a href="/venues" className="text-base text-[#3FBAC2] hover:text-[#339B9F] no-underline py-2 font-bold">Venues</a>

                            {/* Mobile Vendors Dropdown */}
                            <div className="relative" ref={(el) => (dropdownRefs.current["vendors"] = el)}>
                                <button onClick={() => handleDropdownClick("vendors")} className="text-[#3FBAC2] hover:text-[#339B9F] no-underline flex items-center font-bold">
                                    Vendors
                                    <svg className="ml-1 w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                                    </svg>
                                </button>
                                {activeDropdown === "vendors" && (
                                    <div className="absolute left-0 mt-2 w-48 bg-white border rounded shadow-lg">
                                        <a href="/photographers" className="block px-4 py-2 text-[#3FBAC2] hover:bg-gray-100 no-underline font-bold" onClick={(e) => e.stopPropagation()}>
                                            Photographers
                                        </a>
                                        <a href="/djs" className="block px-4 py-2 text-[#3FBAC2] hover:bg-gray-100 no-underline font-bold" onClick={(e) => e.stopPropagation()}>
                                            DJs
                                        </a>
                                    </div>
                                )}
                            </div>

                            <a href="/favourites" className="text-base block text-[#3FBAC2] hover:text-[#339B9F] no-underline py-2 font-bold">Favourites</a>

                            <a href="/vendor_waitlist" className="text-base block text-[#3FBAC2] hover:text-[#339B9F] no-underline py-2 font-bold">Join Vendor Waitlist</a>
                            <a href="/comingsoon" className="text-base block text-[#3FBAC2] hover:text-[#339B9F] no-underline py-2 font-bold">Upcoming Features</a>
                            <button className="block px-3 py-2 mt-2 text-sm text-white bg-[#3FBAC2] rounded hover:bg-[#339B9F] flex items-center space-x-2">
                                <MdAccountCircle size={20} />
                                <a href="/profile" className="text-white hover:text-[#339B9F] no-underline font-bold">Profile</a>
                            </button>
                        </>
                    ) : (
                        <>
                            <a href="/venues" className="text-base text-[#3FBAC2] hover:text-[#339B9F] no-underline py-2 font-bold">Venues</a>

                            {/* Mobile Vendors Dropdown */}
                            <div className="relative" ref={(el) => (dropdownRefs.current["vendors"] = el)}>
                                <button onClick={() => handleDropdownClick("vendors")} className="text-[#3FBAC2] hover:text-[#339B9F] no-underline flex items-center font-bold">
                                    Vendors
                                    <svg className="ml-1 w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                                    </svg>
                                </button>
                                {activeDropdown === "vendors" && (
                                    <div className="absolute left-0 mt-2 w-48 bg-white border rounded shadow-lg">
                                        <a href="/photographers" className="block px-4 py-2 text-[#3FBAC2] hover:bg-gray-100 no-underline font-bold" onClick={(e) => e.stopPropagation()}>
                                            Photographers
                                        </a>
                                        <a href="/djs" className="block px-4 py-2 text-[#3FBAC2] hover:bg-gray-100 no-underline font-bold" onClick={(e) => e.stopPropagation()}>
                                            DJs
                                        </a>
                                    </div>
                                )}
                            </div>

                            <a href="/vendor_waitlist" className="text-base block text-[#3FBAC2] hover:text-[#339B9F] no-underline py-2 font-bold">Join Vendor Waitlist</a>
                            <button className="block px-3 py-2 mt-2 text-sm text-white bg-[#3FBAC2] rounded hover:bg-[#339B9F]"><a href="/login" className="text-white hover:text-[#339B9F] no-underline font-bold">Login</a></button>
                        </>
                    )}
                </div>
            )}
        </nav>
    );
};

export default Navbar;

