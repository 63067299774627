import React, { useState, useContext, useEffect } from 'react';
import NavbarB from './planning_variant/navbar_b.jsx';
import PasswordChecklist from "react-password-checklist";
import { Card } from 'react-bootstrap';
import { AccountContext } from "./Account";
import { useNavigate } from "react-router-dom";
import showPwdImg from '../images/show-password.svg';
import hidePwdImg from '../images/hide-password.svg';
import UserPool from '../UserPool';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function Settings() {
    const [password, setPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [passConfirmed, setPassConfirmed] = useState(false);
    const [isRevealPwd, setIsRevealPwd] = useState(false);
    const [isRevealNewPwd, setIsRevealNewPwd] = useState(false);
    const [isRevealConfirmNewPwd, setIsRevealConfirmNewPwd] = useState(false);
    const [changePasswordError, setChangePasswordError] = useState('');
    const [changePasswordSuccessMessage, setChangePasswordSuccessMessage] = useState('');
    const { getSession } = useContext(AccountContext);
    const navigate = useNavigate();

    useEffect(() => {
        if (UserPool.getCurrentUser() == null) {
            console.log('no user found');
            navigate('/login');
        }
    }, []);

    const changePassword = event => {
        event.preventDefault();

        getSession().then(({ user }) => {
            user.changePassword(password, newPassword, (err, results) => {
                if (err) {
                    console.log(err);
                    setChangePasswordSuccessMessage('');
                    setChangePasswordError(err.message);
                } else {
                    console.log(results);
                    setChangePasswordError('');
                    setChangePasswordSuccessMessage('Successfully updated password');
                }
            });
        });
    };

    return (
        <div>
            <NavbarB />
            <div style={{ height: '100%', width: '100%', padding: '50px' }}>
                <div>
                    <div className='container' style={{ padding: '10px' }}>
                        <div style={{ height: 'auto', justifyContent: 'center', alignItems: 'center', textAlign: 'center', margin: '0 auto' }}>
                            <Container>
                                <Row>
                                    <Col sm={9}>
                                        <Card>
                                            <Card.Body>
                                                <h2 style={{fontWeight: 'bold'}}>Change Password</h2>
                                                <form onSubmit={changePassword} style={{ display: 'inline-block', alignItems: 'center', textAlign: 'center', marginTop: '10px' }}>
                                                    <div className="form-group" style={{ position: 'relative', paddingBottom: '10px' }}>
                                                        <label style={{ fontSize: 25 }}>Current Password</label>
                                                        <input value={password} style={{ padding: '5px 30px 5px 10px', border: '1px solid' }} type={isRevealPwd ? "text" : "password"} id="password" className="form-control" placeholder="Enter current password" onChange={event => setPassword(event.target.value)} />
                                                        <img
                                                            style={{ width: '20px', position: 'absolute', right: '1rem', top: '3rem' }}
                                                            title={isRevealPwd ? "Hide password" : "Show password"}
                                                            src={isRevealPwd ? hidePwdImg : showPwdImg}
                                                            onClick={() => setIsRevealPwd(prevState => !prevState)}
                                                            alt="Hide/Show password"
                                                        />
                                                    </div>

                                                    <div className="form-group" style={{ position: 'relative', paddingBottom: '10px' }}>
                                                        <label style={{ fontSize: 25 }}>New Password</label>
                                                        <input value={newPassword} style={{ padding: '5px 30px 5px 10px', border: '1px solid' }} type={isRevealNewPwd ? "text" : "password"} id="newpassword" className="form-control" placeholder="Enter new password" onChange={event => setNewPassword(event.target.value)} />
                                                        <img
                                                            style={{ width: '20px', position: 'absolute', right: '1rem', top: '3rem' }}
                                                            title={isRevealNewPwd ? "Hide password" : "Show password"}
                                                            src={isRevealNewPwd ? hidePwdImg : showPwdImg}
                                                            onClick={() => setIsRevealNewPwd(prevState => !prevState)}
                                                            alt="Hide/Show password"
                                                        />
                                                    </div>

                                                    <div className="form-group" style={{ marginBottom: '3rem', position: 'relative', paddingBottom: '10px' }}>
                                                        <label style={{ fontSize: 25 }}>Confirm New Password</label>
                                                        <input value={confirmNewPassword} style={{ padding: '5px 30px 5px 10px', border: '1px solid' }} type={isRevealConfirmNewPwd ? "text" : "password"} id="confirmnewpassword" className="form-control" placeholder="Confirm new password" onChange={event => setConfirmNewPassword(event.target.value)} />
                                                        <img
                                                            style={{ width: '20px', position: 'absolute', right: '1rem', top: '3rem' }}
                                                            title={isRevealConfirmNewPwd ? "Hide password" : "Show password"}
                                                            src={isRevealConfirmNewPwd ? hidePwdImg : showPwdImg}
                                                            onClick={() => setIsRevealConfirmNewPwd(prevState => !prevState)}
                                                            alt="Hide/Show password"
                                                        />
                                                        <PasswordChecklist
                                                            rules={["minLength", "specialChar", "number", "capital", "lowercase", "match"]}
                                                            minLength={8}
                                                            value={newPassword}
                                                            valueAgain={confirmNewPassword}
                                                            onChange={(isValid) => setPassConfirmed(isValid)}
                                                            style={{ paddingTop: '10px' }}
                                                        />
                                                        <div style={{ float: 'left', color: 'red', fontSize: '20px' }}>{changePasswordError}</div>
                                                        <div style={{ float: 'left', color: 'green', fontSize: '20px' }}>{changePasswordSuccessMessage}</div>
                                                    </div>

                                                    <button type="submit" disabled={!passConfirmed} style={buttonStyle} className="btn btn-block">Change password</button>
                                                </form>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                    <Col sm={3}>
                                        <a href="/profile" style={buttonStyle} className="btn btn-block">Back to Profile</a>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Settings;

const buttonStyle = {
    marginTop: '10px',
    backgroundColor: "#3FBAC2",
    color: 'white',
    fontWeight: 'bold',
    fontSize: 25,
    fontFamily: 'Mulish'
};
