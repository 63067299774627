import React, { useState, useMemo, useEffect, useContext } from 'react';
import { AiOutlineHeart, AiFillHeart } from 'react-icons/ai';
import { Link } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useMediaQuery } from 'react-responsive';
import axios from 'axios';
import { Button, Card } from "flowbite-react";
import ClipLoader from "react-spinners/ClipLoader";
import NavbarB from './planning_variant/navbar_b.jsx';
import ReactGA from "react-ga4";
import Bottom from './bottom.jsx';
import { AccountContext } from "./Account";
import UserPool from '../UserPool';
import venue from '../images/venue.jpg';
import photo from '../images/photography.jpg';
import dj from '../images/dj.jpg';
import { FaSearch } from "react-icons/fa";

function UserFavourites() {
    const [errorMessage, setErrorMessage] = useState('');
    const [venueFavs, setVenueFavs] = useState(new Set());  // State for managing venue favourites
    const [photoFavs, setPhotoFavs] = useState(new Set());  // State for managing photographer favourites
    const [djFavs, setDjFavs] = useState(new Set());  // State for managing dj favourites
    const { getSession } = useContext(AccountContext);
    const [userID, setUserID] = useState(null);
    const [venueFetched, setVenueFetched] = useState(false);
    const [photoFetched, setPhotoFetched] = useState(false);
    const [djFetched, setDjFetched] = useState(false);

    ReactGA.send({ hitType: "pageview", page: "/favourites", title: "Favourites Visit" });

    useEffect(() => {
        console.log('here');
        if (UserPool.getCurrentUser() == null) {
            setUserID(null);
        } else {
            getSession().then(({ user }) => {
                setUserID(String(user.username));
            });
        }
    }, [getSession]);

    const fetchVenueFavourites = async (userID) => {
        try {
            const response = await axios.get(`https://bt5udcg0qk.execute-api.us-east-1.amazonaws.com/favourites?userID=${userID}`);
            setVenueFetched(true);
            return new Set(response.data.venueFavs);
        } catch (err) {
            console.error('Error fetching venue favourites:', err);
            // throw err; 
        }
    };

    const fetchPhotoFavourites = async (userID) => {
        try {
            const response = await axios.get(`https://l1279w6urd.execute-api.us-east-1.amazonaws.com/favourites?userID=${userID}`);
            setPhotoFetched(true);
            return new Set(response.data.photoFavs);
        } catch (err) {
            console.error('Error fetching photo favourites:', err);
            // throw err; 
        }
    };

    const fetchDjFavourites = async (userID) => {
        try {
            const response = await axios.get(`https://7r8qoyhum0.execute-api.us-east-1.amazonaws.com/favourites?userID=${userID}`);
            setDjFetched(true);
            return new Set(response.data.djFavs);
        } catch (err) {
            console.error('Error fetching DJ favourites:', err);
            // throw err; 
        }
    };

    const getFavourites = async (userID) => {
        try {
            const [venueFavs, photoFavs, djFavs] = await Promise.all([
                fetchVenueFavourites(userID),
                fetchPhotoFavourites(userID),
                fetchDjFavourites(userID),
            ]);

            setVenueFavs(venueFavs);
            setPhotoFavs(photoFavs);
            setDjFavs(djFavs);
        } catch (err) {
            setErrorMessage('Error fetching favourites');
            console.error('Error fetching favourites:', err);
        }
    };

    React.useEffect(() => {
        if (userID) {
            getFavourites(userID);
        }
    }, [userID]);


    const isMobile = useMediaQuery({ maxWidth: 984 }); // Define your mobile screen width here

    return (
        <div>
            <NavbarB />
            <div style={{ height: '100%', width: '100%' }}>
                <div>
                    {isMobile ?
                        (
                            <div className='container' style={{ paddingTop: '15px', paddingBottom: '15px' }}>
                                <div style={{ height: 'auto', justifyContent: 'center', alignItems: 'center', textAlign: 'center', margin: '0 auto' }}>
                                    <h1 className='col-mb-3' style={{ fontWeight: 'bold', fontSize: 30, paddingBottom: '2px' }}>Favourites</h1>
                                </div>
                            </div>
                        ) :
                        (
                            <div className='container' style={{ paddingTop: '15px', paddingBottom: '15px' }}>
                                <div style={{ height: 'auto', justifyContent: 'center', alignItems: 'center', textAlign: 'center', margin: '0 auto' }}>
                                    <h1 className='col-mb-3' style={{ fontWeight: 'bold', fontSize: 45, paddingBottom: '2px' }}>Favourites</h1>
                                </div>
                            </div>
                        )
                    }
                    <div className='container' style={{ padding: '5px' }}>
                        <div style={{ height: 'auto', justifyContent: 'center', alignItems: 'center', textAlign: 'center', margin: '0 auto' }}>
                            {isMobile ? (
                                <Container>
                                    <Row>
                                        <div className="overflow-x-auto flex space-x-4 pb-4">
                                            <Card
                                                className="relative flex-shrink-0 w-55 flex flex-col overflow-hidden p-0 bg-cover bg-center"
                                                style={{ backgroundImage: `url(${venue})`, backgroundSize: 'cover', backgroundPosition: 'center', width: '250px', height: '250px' }}
                                            >
                                                <div
                                                    className="absolute inset-0"
                                                    style={{ backgroundColor: 'rgba(0, 0, 0, 0.6)' }}
                                                />
                                                <div className="relative flex flex-col justify-center items-center p-4 text-white text-center">
                                                    <h5 className="text-xl font-bold tracking-tight text-white text-center m-0 mb-1">
                                                        Venues
                                                    </h5>
                                                    {venueFetched === false ? (
                                                        <ClipLoader color="#32969C" size={50} />
                                                    ) : (
                                                        <div className="relative flex flex-col justify-center items-center p-2 text-white text-center">
                                                            <p className="flex items-center font-normal text-gray-200 text-left m-0 mb-1">
                                                                <AiFillHeart color="#3FBAC2" size={24} className="mr-1" />
                                                                <strong style={{ fontSize: 20 }}>{venueFavs.size}</strong>
                                                            </p>
                                                            <div className="mt-auto">
                                                                {venueFavs.size > 0 ? (
                                                                    <Button className="mt-2 bg-zenplanColour">
                                                                        <Link style={{ color: 'white', textDecoration: 'none' }} to='/venue-favourites' >
                                                                            View Favourites
                                                                        </Link>
                                                                    </Button>
                                                                ) : (
                                                                    <Button className="mt-2 bg-zenplanColour">
                                                                        <FaSearch size={25} style={{marginRight: '4px', marginTop: '4px'}}/>
                                                                        <Link style={{ color: 'white', textDecoration: 'none' }} to='/venues'>
                                                                            Explore Venues
                                                                        </Link>
                                                                    </Button>
                                                                )}
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </Card>
                                            <Card
                                                className="relative flex-shrink-0 w-55 flex flex-col overflow-hidden p-0 bg-cover bg-center"
                                                style={{ backgroundImage: `url(${photo})`, backgroundSize: 'cover', backgroundPosition: 'center', width: '250px', height: '250px' }}
                                            >
                                                <div
                                                    className="absolute inset-0"
                                                    style={{ backgroundColor: 'rgba(0, 0, 0, 0.6)' }}
                                                />
                                                <div className="relative flex flex-col justify-center items-center p-4 text-white text-center">
                                                    <h5 className="text-xl font-bold tracking-tight text-white text-center m-0 mb-1">
                                                        Photographers
                                                    </h5>
                                                    {photoFetched === false ? (
                                                        <ClipLoader color="#32969C" size={50} />
                                                    ) : (
                                                        <div className="relative flex flex-col justify-center items-center p-2 text-white text-center">
                                                            <p className="flex items-center font-normal text-gray-200 text-left m-0 mb-1">
                                                                <AiFillHeart color="#3FBAC2" size={24} className="mr-1" />
                                                                <strong style={{ fontSize: 20 }}>{photoFavs.size}</strong>
                                                            </p>
                                                            <div className="mt-auto">
                                                                {photoFavs.size > 0 ? (
                                                                    <Button className="mt-2 bg-zenplanColour">
                                                                        <Link style={{ color: 'white', textDecoration: 'none' }} to='/photo-favourites' >
                                                                            View Favourites
                                                                        </Link>
                                                                    </Button>
                                                                ) : (
                                                                    <Button className="mt-2 bg-zenplanColour">
                                                                        <FaSearch size={25} style={{marginRight: '4px', marginTop: '4px'}}/>
                                                                        <Link style={{ color: 'white', textDecoration: 'none' }} to='/photographers' >
                                                                            Explore Photographers
                                                                        </Link>
                                                                    </Button>
                                                                )}
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </Card>

                                            <Card
                                                className="relative flex-shrink-0 w-55 flex flex-col overflow-hidden p-0 bg-cover bg-center"
                                                style={{ backgroundImage: `url(${dj})`, backgroundSize: 'cover', backgroundPosition: 'center', width: '250px', height: '250px' }}
                                            >
                                                <div
                                                    className="absolute inset-0"
                                                    style={{ backgroundColor: 'rgba(0, 0, 0, 0.6)' }}
                                                />
                                                <div className="relative flex flex-col justify-center items-center p-4 text-white text-center">
                                                    <h5 className="text-xl font-bold tracking-tight text-white text-center m-0 mb-1">
                                                        DJs
                                                    </h5>
                                                    {djFetched === false ? (
                                                        <ClipLoader color="#32969C" size={50} />
                                                    ) : (
                                                        <div className="relative flex flex-col justify-center items-center p-2 text-white text-center">
                                                            <p className="flex items-center font-normal text-gray-200 text-left m-0 mb-1">
                                                                <AiFillHeart color="#3FBAC2" size={24} className="mr-1" />
                                                                <strong style={{ fontSize: 20 }}>{djFavs.size}</strong>
                                                            </p>
                                                            <div className="mt-auto">
                                                                {djFavs.size > 0 ? (
                                                                    <Button className="mt-2 bg-zenplanColour">
                                                                        <Link style={{ color: 'white', textDecoration: 'none' }} to='/dj-favourites' >
                                                                            View Favourites
                                                                        </Link>
                                                                    </Button>
                                                                ) : (
                                                                    <Button className="mt-2 bg-zenplanColour">
                                                                        <FaSearch size={25} style={{marginRight: '4px', marginTop: '4px'}}/>
                                                                        <Link style={{ color: 'white', textDecoration: 'none' }} to='/djs' >
                                                                            Explore DJs
                                                                        </Link>
                                                                    </Button>
                                                                )}
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </Card>
                                        </div>

                                    </Row>
                                </Container>
                            ) : (
                                <Container>
                                    <Row>
                                        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 mb-10">
                                            <Card
                                                className="relative flex flex-col justify-center items-center overflow-hidden bg-cover bg-center"
                                                style={{ backgroundImage: `url(${venue})`, backgroundSize: 'cover', backgroundPosition: 'center' }}
                                            >
                                                <div
                                                    className="absolute inset-0"
                                                    style={{ backgroundColor: 'rgba(0, 0, 0, 0.6)' }}
                                                />
                                                <div className="relative flex flex-col justify-center items-center p-4 text-white text-center">
                                                    <h5 className="text-xl font-bold tracking-tight text-center m-0 mb-1">
                                                        Venues
                                                    </h5>
                                                    {venueFetched === false ? (
                                                        <ClipLoader color="#32969C" size={50} />
                                                    ) : (
                                                        <div className="relative flex flex-col justify-center items-center p-2 text-white text-center">
                                                            <p className="flex items-center font-normal text-gray-200 text-left m-0 mb-1">
                                                                <AiFillHeart color="#3FBAC2" size={24} className="mr-1" />
                                                                <strong style={{ fontSize: 20 }}>{venueFavs.size}</strong>
                                                            </p>
                                                            <div className="mt-auto">
                                                                {venueFavs.size > 0 ? (
                                                                    <Button className="mt-2 bg-zenplanColour">
                                                                        <Link style={{ color: 'white', textDecoration: 'none' }} to='/venue-favourites' >
                                                                            View Favourites
                                                                        </Link>
                                                                    </Button>
                                                                ) : (
                                                                    <Button className="mt-2 bg-zenplanColour">
                                                                        <FaSearch style={{marginRight: '4px', marginTop: '4px'}}/>
                                                                        <Link style={{ color: 'white', textDecoration: 'none' }} to='/venues' >
                                                                            Explore Venues
                                                                        </Link>
                                                                    </Button>
                                                                )}
                                                            </div>
                                                        </div>
                                                    )}

                                                </div>
                                            </Card>
                                            <Card
                                                className="relative flex flex-col justify-center items-center overflow-hidden bg-cover bg-center"
                                                style={{ backgroundImage: `url(${photo})`, backgroundSize: 'cover', backgroundPosition: 'center' }}
                                            >
                                                <div
                                                    className="absolute inset-0"
                                                    style={{ backgroundColor: 'rgba(0, 0, 0, 0.6)' }}
                                                />
                                                <div className="relative flex flex-col justify-center items-center p-4 text-white text-center">
                                                    <h5 className="text-xl font-bold tracking-tight text-center m-0 mb-1">
                                                        Photographers
                                                    </h5>
                                                    {photoFetched === false ? (
                                                        <ClipLoader color="#32969C" size={50} />
                                                    ) : (
                                                        <div className="relative flex flex-col justify-center items-center p-2 text-white text-center">
                                                            <p className="flex items-center font-normal text-gray-200 text-left m-0 mb-1">
                                                                <AiFillHeart color="#3FBAC2" size={24} className="mr-1" />
                                                                <strong style={{ fontSize: 20 }}>{photoFavs.size}</strong>
                                                            </p>
                                                            <div className="mt-auto">
                                                                {photoFavs.size > 0 ? (
                                                                    <Button className="mt-2 bg-zenplanColour">
                                                                        <Link style={{ color: 'white', textDecoration: 'none' }} to='/photo-favourites' >
                                                                            View Favourites
                                                                        </Link>
                                                                    </Button>
                                                                ) : (
                                                                    <Button className="mt-2 bg-zenplanColour">
                                                                        <FaSearch style={{marginRight: '4px', marginTop: '4px'}}/>
                                                                        <Link style={{ color: 'white', textDecoration: 'none' }} to='/photographers' >
                                                                            Explore Photographers
                                                                        </Link>
                                                                    </Button>
                                                                )}
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </Card>
                                            <Card
                                                className="relative flex flex-col justify-center items-center overflow-hidden bg-cover bg-center"
                                                style={{ backgroundImage: `url(${dj})`, backgroundSize: 'cover', backgroundPosition: 'center' }}
                                            >
                                                <div
                                                    className="absolute inset-0"
                                                    style={{ backgroundColor: 'rgba(0, 0, 0, 0.6)' }}
                                                />
                                                <div className="relative flex flex-col justify-center items-center p-4 text-white text-center">
                                                    <h5 className="text-xl font-bold tracking-tight text-center m-0 mb-1">
                                                        DJs
                                                    </h5>
                                                    {djFetched === false ? (
                                                        <ClipLoader color="#32969C" size={50} />
                                                    ) : (
                                                        <div className="relative flex flex-col justify-center items-center p-2 text-white text-center">
                                                            <p className="flex items-center font-normal text-gray-200 text-left m-0 mb-1">
                                                                <AiFillHeart color="#3FBAC2" size={24} className="mr-1" />
                                                                <strong style={{ fontSize: 20 }}>{djFavs.size}</strong>
                                                            </p>
                                                            <div className="mt-auto">
                                                                {djFavs.size > 0 ? (
                                                                    <Button className="mt-2 bg-zenplanColour">
                                                                        <Link style={{ color: 'white', textDecoration: 'none' }} to='/dj-favourites' >
                                                                            View Favourites
                                                                        </Link>
                                                                    </Button>
                                                                ) : (
                                                                    <Button className="mt-2 bg-zenplanColour">
                                                                        <FaSearch style={{marginRight: '4px', marginTop: '4px'}}/>
                                                                        <Link style={{ color: 'white', textDecoration: 'none' }} to='/djs' >
                                                                            Explore DJs
                                                                        </Link>
                                                                    </Button>
                                                                )}
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </Card>
                                        </div>
                                    </Row>
                                </Container>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <Bottom />
        </div>
    );
}

export default UserFavourites;
