import React, { useState } from 'react';
import VariantA from './transparent_pricing_variant/variant_a.jsx';
import VariantB from './planning_variant/variant_b.jsx';
import ReactGA from "react-ga4";
import { useExperiment } from 'react-ab-tasty';

export default function Home() {
  // const { ExperimentComponent } = useExperiment({
  //   variants: [<VariantA />, <VariantB />], // React components for each variant
  //   weights: [50, 50], // Probability weights for each variant
  //   storageType: 'local', // Optional, 'local' or 'session', defaults to 'local'
  //   storageKey: 'experimentWin', // Optional, key used in storage, defaults to 'experimentWin'
  //   enableLogging: false, // Optional, enables logging if true
  // });

  return (
    <div>
      <VariantB />
    </div>
  );
}
