import React, { useState } from 'react';
import axios from 'axios';
import ReactGA from "react-ga4";
import logo from '../../images/logo.png';
import { InlineWidget } from "react-calendly";
import NavbarB from './navbar_b.jsx';
import Bottom from '../bottom.jsx';

export default function UserWaitlistB() {
    const [fullName, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [city_region, setCity_region] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [stage, setStage] = useState(1);  // 1 = form, 2 = success
    const [errorMessage, setErrorMessage] = useState('');
    const [submitted, setSubmitted] = useState(false);

    function joinWaitlist(e) {
        e.preventDefault();

        let request = {
            fullName: fullName,
            email: email,
            phoneNumber: phoneNumber,
            city_region: city_region,
            variant_name: 'Full Planning Solution',
            updated_at: Date()
        }

        console.log(request);

        axios.post('https://l3sf33aj40.execute-api.us-east-1.amazonaws.com/waitlist', request)
            .then(resp => {
                console.log("Added " + email + " to waitlist:")
                console.log(resp)

                ReactGA.event({
                    category: "Full Planning Variant Signup",
                    action: "Full Planning Variant Signup",
                    label: "Full Planning Variant Signup"
                });

                setSubmitted(true);
            })
            .catch(err => {
                console.log(err);
                setErrorMessage(err.message);
            })
    }


    return (
        <div>
            <NavbarB />
            <div style={{ height: '100%', width: '100%', padding: '50px' }}>
                <div>
                    <div className='container' style={{ padding: '10px' }}>
                        <div style={{ visibility: submitted ? 'hidden' : 'visible', height: submitted ? '1px' : 'auto', align: 'center', textAlign: 'center' }}>
                            <h1 className='col-mb-3' style={{ fontWeight: 'bold', fontSize: 45 }}>Join The Waitlist Now!</h1>
                            <h3 className='col-mb-3'>Save time and reduce stress planning your dream wedding. <br></br> We will contact you when we launch our product!</h3>
                            <form onSubmit={(e) => joinWaitlist(e)} style={{ display: 'inline-block', alignItems: 'center', textAlign: 'center', marginTop: '10px' }}>
                                <div className="form-group" style={{ paddingBottom: '10px' }}>
                                    <label style={{ fontSize: 25 }}>Full Name</label>
                                    <input style={{ border: '1px solid' }} value={fullName} type="text" id="fullName" className="form-control" placeholder="Enter Full Name" onChange={(event) => setFullName(event.target.value)} required />
                                </div>

                                <div className="form-group" style={{ paddingBottom: '10px' }}>
                                    <label style={{ fontSize: 25 }}>Email address</label>
                                    <input style={{ border: '1px solid' }} value={email} type="email" id="userEmail" className="form-control" placeholder="Enter email" required onChange={(event) => setEmail(event.target.value)} />
                                </div>

                                <div className='form-group' style={{ paddingBottom: '10px' }}>
                                    <label style={{ fontSize: 25 }}>Phone Number</label>
                                    <input
                                        value={phoneNumber}
                                        type='text'
                                        id='phoneNumber'
                                        name='phoneNumber'
                                        className='form-control'
                                        placeholder='e.g. ###-###-####'
                                        style={{ border: '1px solid' }}
                                        required
                                        onChange={(event) => setPhoneNumber(event.target.value)}
                                    />
                                </div>

                                <div className="form-group" style={{ paddingBottom: '10px' }}>
                                    <label style={{ fontSize: 25 }}>What city/region do you live in?</label>
                                    <input style={{ border: '1px solid' }} value={city_region} type="text" id="city" className="form-control" placeholder="Enter city/region" required onChange={(event) => setCity_region(event.target.value)} />
                                </div>

                                <button type="submit" className="btn btn-block" style={buttonStyle}>Join Waitlist</button>
                            </form>
                        </div>
                        <div style={{ visibility: submitted ? 'visible' : 'hidden', height: submitted ? 'auto' : '1px', align: 'center', textAlign: 'center' }}>
                            <h1>You have been added to the waitlist!</h1>
                            <h3>Please sign up for a free 30 minute call using out Calendly link below, to find out how we can help you!</h3>

                            <InlineWidget url="https://calendly.com/zenplan-meeting/zenplan-help-you-plan-your-wedding" />

                            <a href='/'><button className="btn btn-block" style={buttonStyle}>Home Page</button></a>
                        </div>
                    </div>
                </div>
            </div>
            <Bottom />
        </div>
    );
}

const buttonStyle = {
    marginTop: '50px',
    backgroundColor: "#3FBAC2",
    width: '200px',
    color: 'white',
    fontWeight: 'bold',
    fontSize: 25,
    fontFamily: 'Raleway'
};
