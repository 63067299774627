import React, { useState } from 'react';
import FeaturesB from './features_b.jsx';
import HeaderB from './header_b.jsx';
import WaitlistB from './waitlist_b.jsx';
import Bottom from '../bottom.jsx';
import About from './about.jsx';
import ReactGA from "react-ga4";

ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "Planning Variant Visit" });

export default function VariantB() {
  return (
    <div>
      <HeaderB />
      <FeaturesB />
      <About />
      <WaitlistB />
      <Bottom />
    </div>
  );
}
