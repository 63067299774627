import React, { useState } from 'react';
import FeaturesA from './features_a.jsx';
import HeaderA from './header_a.jsx';
import WaitlistA from './waitlist_a.jsx';
import Bottom from '../bottom.jsx';
import ReactGA from "react-ga4";

ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "Transparent Pricing Variant Visit" });

export default function VariantA() {
  return (
    <div>
      <HeaderA />
      <FeaturesA />
      <WaitlistA />
      <Bottom />
    </div>
  );
}
