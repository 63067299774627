import React, { useState } from 'react';
import logo from '../../images/logo.png';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import headerPhoto from '../../images/header_photo.jpg';
import headerPhotoMobile from '../../images/header_photo_mobile.jpg';
import NavbarB from './navbar_b.jsx';
import { useMediaQuery } from 'react-responsive';
import newHeaderPhoto from '../../images/newheaderphoto.jpg';

export default function HeaderB() {
    const isMobile = useMediaQuery({ maxWidth: 984 }); // Define your mobile screen width here

    return (
        <div>
            <NavbarB />
            <header>
                <Container>
                    {isMobile ?
                        (
                            <div>
                                <Row style={{ paddingTop: '20px', paddingLeft: '10px', paddingRight: '10px' }}>
                                    <Col sm={12}><img style={{ width: '100%', height: '100%', boxShadow: '5px 5px 5px 5px #ccc', borderRadius: '10px' }} src={newHeaderPhoto}></img></Col>
                                </Row>
                                <Row style={{ paddingTop: '30px' }}>
                                    <Col sm={12}>
                                        <h1 className='mb-3' style={{ fontWeight: 'bold', fontSize: 30, textAlign: 'center', }}>
                                            Plan Your <span style={{ color: "#32969C" }}>Dream Wedding Stress Free,</span> In Ontario
                                        </h1>
                                        <h3 style={{ fontWeight: 'bold', fontSize: 20, paddingTop: '10px', paddingLeft: '10px', paddingRight: '10px', textAlign: 'center', }} className='mb-3'>
                                            Find <span style={{ color: "#32969C" }}>Transparent Pricing</span> Using Our Intuitive Planning Tools
                                        </h3>
                                    </Col>
                                </Row>
                            </div>
                        ) :
                        (
                            <div style={{ paddingTop: '20px' }}>
                                <Row>
                                    <Col sm={6}>
                                        <h1 className='mb-3' style={{ fontWeight: 'bold', fontSize: 55, paddingTop: '50px' }}>
                                            Plan Your <span style={{ color: "#32969C" }}>Dream Wedding Stress Free,</span> In Ontario
                                        </h1>
                                        <h3 style={{ fontWeight: 'bold', fontSize: 25 }} className='mb-3'>Find <span style={{ color: "#32969C" }}>Transparent Pricing</span> Using Our Intuitive Planning Tools</h3>
                                    </Col>
                                    <Col sm={6}><img style={{ width: '100%', boxShadow: '5px 5px 5px 5px #ccc', borderRadius: '10px' }} src={newHeaderPhoto}></img></Col>
                                </Row>
                            </div>
                        )
                    }

                </Container>
            </header>
        </div>
    );
}
