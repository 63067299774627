import React, { useState, useEffect, useContext } from "react"
import NavbarB from './planning_variant/navbar_b.jsx';
import Bottom from './bottom.jsx';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import { Tooltip } from 'react-tooltip'
import { MdVerified } from "react-icons/md";
import { useLocation, useParams, useNavigate } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive';
import axios from 'axios';
import { FaExternalLinkAlt } from "react-icons/fa";
import { Link } from "react-router-dom";
import StarRatings from "react-star-ratings";
import { IoPerson } from "react-icons/io5";
import ReactGA from "react-ga4";
import { AccountContext } from "./Account";
import { Button } from "flowbite-react";
import { AiOutlineHeart, AiFillHeart } from 'react-icons/ai';
import UserPool from '../UserPool';

function VenueDetail() {
    const location = useLocation();
    const navigate = useNavigate();
    const { vendorId } = useParams();
    const [record, setRecord] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');
    const [status, setStatus] = useState(false);
    const { getSession, logout } = useContext(AccountContext);
    const [favourites, setFavourites] = useState(new Set());  // State for managing user favourites
    const [userID, setUserID] = useState(null);

    useEffect(() => {
        getSession()
            .then((session) => {
                console.log("Session: ", session);
                setStatus(true); // Set status to true if session exists
            })
            .catch(() => {
                setStatus(false); // Set status to false if session doesn't exist
            });

        // Function to fetch data
        const fetchData = async () => {
            try {
                const response = await axios.get(`https://a7okqrhxf9.execute-api.us-east-1.amazonaws.com/items/${vendorId}`);
                console.log('calling api')
                setRecord(response.data);
            } catch (error) {
                setErrorMessage('Failed to fetch data');
                console.error(error);
            }
        };

        // Check if data is passed through `location.state`
        if (location.state) {
            setRecord(location.state);
        } else {
            fetchData();
        }
    }, [vendorId, location.state, getSession]); // Dependency on `vendorId` and `location.state` to refetch if these change

    useEffect(() => {
        console.log('here');
        if (UserPool.getCurrentUser() == null) {
            setUserID(null);
        } else {
            getSession().then(({ user }) => {
                setUserID(String(user.username));
            });
        }
    }, [getSession]);

    useEffect(() => {
        if (userID) {
            axios.get(`https://bt5udcg0qk.execute-api.us-east-1.amazonaws.com/favourites?userID=${userID}`)
                .then(response => {
                    setFavourites(new Set(response.data.venueFavs));
                })
                .catch(error => {
                    console.error('Error fetching favorites:', error);
                });
        }
    }, [userID]);

    const handleFavouriteClick = (vendorId) => {
        if (!userID) {
            // Redirect to login page if user is not logged in
            navigate('/login');
            return;
        }

        const updatedFavourites = new Set(favourites);
        const action = updatedFavourites.has(vendorId) ? 'remove' : 'add';

        if (action === 'remove') {
            updatedFavourites.delete(vendorId);
        } else {
            updatedFavourites.add(vendorId);
        }
        setFavourites(updatedFavourites);

        try {
            // Call API to save the entire updated favorites list to the database
            axios.post('https://bt5udcg0qk.execute-api.us-east-1.amazonaws.com/favourites', {
                userID,
                updatedFavourites: Array.from(updatedFavourites),
            });
            console.log(`Favorite updated successfully.`);
        } catch (error) {
            console.error('Error updating favorites:', error);
        }
    };

    const websiteVisit = (e) => {
        e.preventDefault();
        ReactGA.event({
            category: record.name + " Website Visit",
            action: record.name + " Website Visit",
            label: record.name + " Website Visit"
        });

        window.open(record.website_link, "_blank");
    };

    const handleLoginRedirect = () => {
        // Redirect to login page with the current path as a query parameter
        const redirectUrl = encodeURIComponent(location.pathname + location.search);
        localStorage.setItem('redirectAfterLogin', redirectUrl);
        navigate('/login', { replace: true });
    };

    const isMobile = useMediaQuery({ maxWidth: 984 }); // Define your mobile screen width here

    if (errorMessage) {
        return <div>Error: {errorMessage}</div>;
    }

    if (!record) {
        return <div>Loading...</div>;
    }

    return (
        <div>
            <NavbarB />
            <div style={{ height: '100%', width: '100%' }}>
                <div>
                    {isMobile ? (
                        <div className='container' style={{ paddingTop: '15px', paddingBottom: '15px' }}>
                            <div className="relative w-full" style={{ display: 'flex', justifyContent: 'center', marginBottom: '15px' }}>
                                <img
                                    src={`https://d21i5s43fw3pjx.cloudfront.net/${record.s3_path[0]}`}
                                    alt="image"
                                    style={{
                                        boxShadow: '3px 3px 3px 3px #ccc',
                                        borderRadius: '10px',
                                        width: '100%',
                                        height: 'auto'
                                    }}
                                />
                                <button onClick={() => handleFavouriteClick(record.id)} className="absolute top-1 right-1 p-1 rounded-full" style={{ backgroundColor: 'rgba(255, 255, 255, 1)' }} >
                                    {favourites.has(record.id) ? <AiFillHeart color="#3FBAC2" size={25} /> : <AiOutlineHeart color="#3FBAC2" size={25} />}
                                </button>
                            </div>
                            <div style={{ height: 'auto', justifyContent: 'center', alignItems: 'center', margin: '0 auto' }}>
                                <Container>
                                    <Row style={{ textAlign: 'left' }}>
                                        <Col md={12}>
                                            <h1 className='col-mb-3' style={{ fontWeight: 'bold', fontSize: 20, paddingBottom: '10px' }}>{record.name}</h1>
                                            <h4 className='col-mb-3' style={{ paddingBottom: '10px', fontSize: 15 }}>{record.address}</h4>
                                            <h4 className='col-mb-3' style={{ paddingBottom: '10px', fontSize: 15 }}>
                                                <span>{record.review_rating.toString().includes('.') ? record.review_rating : `${record.review_rating}.0`}</span>
                                                <StarRatings
                                                    rating={record.review_rating}
                                                    starRatedColor="#3FBAC2"
                                                    numberOfStars={5}
                                                    name='rating'
                                                    starDimension="20px"
                                                    starSpacing='1px'
                                                />
                                                {' '}({record.number_of_reviews} reviews)
                                            </h4>
                                            <Table style={{ width: '100%', textAlign: 'left' }} responsive>
                                                <tbody>
                                                    <tr>
                                                        <div style={{ margin: '5px', border: '2px solid #3FBAC2', padding: '10px', borderRadius: '10px', fontSize: 15 }}>
                                                            <div><strong>Starting Price Per Head: </strong> ${record.starting_price_per_head}</div>
                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                <strong>Seating Capacity: </strong>
                                                                <div className="ml-1">
                                                                    {record.seating_capacity_min}-{record.seating_capacity_max}
                                                                </div>
                                                                <IoPerson className="ml-1" style={{ color: '#3FBAC2' }} />
                                                            </div>
                                                            <div><strong>Number of Event Spaces: </strong> {record.number_event_spaces}</div>
                                                            <div><strong>Reception Hall Rental Costs: </strong> {record.starting_cost_reception_hall}</div>
                                                            {/* <div><strong>Reception Hall Rental Notes: </strong> {record.reception_hall_rental_notes}</div> */}
                                                        </div>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                            <div style={{ textAlign: 'center', paddingBottom: '10px' }}>
                                                <Button className="btn btn-block flex items-center" style={buttonStyle} onClick={websiteVisit}>
                                                    <FaExternalLinkAlt className="mr-2 h-5 w-5" />
                                                    Visit Vendor Website
                                                </Button>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <h4 className='col-mb-3' style={{ paddingTop: '20px', fontSize: 15 }}>{record.description}</h4>
                                    </Row>
                                    <Row style={{ marginTop: '30px', justifyContent: 'center' }}>
                                        <Col md={10}>
                                            <span style={{ textAlign: 'center' }}>
                                                <h1 style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: 20 }}>Vendor Verified Pricing <a style={{ display: 'flex', alignItems: 'center', marginLeft: '8px' }} data-tooltip-id="my-tooltip" data-tooltip-content="Vendor pricing provided directly to Zenplan">
                                                    <MdVerified style={{ color: "#3FBAC2" }} />
                                                </a> <Tooltip style={{ backgroundColor: "#ABD3FF", color: 'black', fontSize: 15, borderRadius: '10px' }} id="my-tooltip" /></h1>
                                            </span>
                                            <div style={{ position: "relative", overflow: "hidden" }}>
                                                <div style={{ filter: status ? "none" : "blur(10px)" }}>
                                                    <Table striped bordered style={tableStyle}>
                                                        <tbody>
                                                            {Object.entries(JSON.parse(record.packages)).map(([packageType, packages]) =>
                                                                packages.map((pkg, index) => (
                                                                    <div key={index} style={{ margin: '10px', border: '1px solid #ccc', padding: '10px', fontSize: 13 }}>
                                                                        <div><strong>Package Type:</strong> {packageType}</div>
                                                                        <div><strong>Package Name:</strong> {pkg.Name}</div>
                                                                        {Object.keys(pkg).filter(key => key !== 'Name').map(detailKey => (
                                                                            <div key={detailKey}>
                                                                                <strong>{detailKey}:</strong> {detailKey.toLowerCase() === 'price per head' ? `$${pkg[detailKey]}` : pkg[detailKey]}
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                ))
                                                            )}
                                                        </tbody>
                                                    </Table>
                                                </div>
                                                {!status && (
                                                    <div style={{ position: "absolute", top: "1%", left: "50%", transform: "translate(-50%)", backgroundColor: "rgba(255, 255, 255, 0.9)", padding: "10px 20px", borderRadius: "10px", textAlign: "center", zIndex: 10 }}>
                                                        <strong>Please log in to view the full details of the packages</strong><br></br>
                                                        <Link to='/login'>
                                                            <button className='btn btn-block' onClick={handleLoginRedirect} style={navBarButtonStyles}>Login</button>
                                                        </Link>
                                                    </div>
                                                )}
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                        </div>
                    ) : (
                        <div className='container' style={{ paddingTop: '15px', paddingBottom: '15px' }}>
                            <div style={{ height: 'auto', justifyContent: 'center', alignItems: 'center', margin: '0 auto' }}>
                                <Container>
                                    <Row style={{ textAlign: 'center' }}>
                                        <Col md={5}>
                                            <div className="relative w-full">
                                                <img src={`https://d21i5s43fw3pjx.cloudfront.net/${record.s3_path[0]}`} alt="venue" style={{ boxShadow: '3px 3px 3px 3px #ccc', borderRadius: '10px', width: '100%', height: '100%' }} />
                                                <button onClick={() => handleFavouriteClick(record.id)} className="absolute top-2 right-2 p-2 rounded-full bg-white shadow-lg" style={{ border: 'none' }} >
                                                    {favourites.has(record.id) ? <AiFillHeart color="#3FBAC2" size={35} /> : <AiOutlineHeart color="#3FBAC2" size={35} />}
                                                </button>
                                            </div>
                                        </Col>
                                        <Col md={7}>
                                            <h1 className='col-mb-3' style={{ fontWeight: 'bold', fontSize: 45, paddingBottom: '10px' }}>{record.name}</h1>
                                            <h4 className='col-mb-3' style={{ paddingBottom: '10px' }}>{record.address}</h4>
                                            <h4 className='col-mb-3' style={{ paddingBottom: '10px' }}>
                                                <span>{record.review_rating.toString().includes('.') ? record.review_rating : `${record.review_rating}.0`}</span>
                                                <StarRatings
                                                    rating={record.review_rating}
                                                    starRatedColor="#3FBAC2"
                                                    numberOfStars={5}
                                                    name='rating'
                                                    starDimension="20px"
                                                    starSpacing='1px'
                                                />
                                                {' '}({record.number_of_reviews} reviews)
                                            </h4>
                                            <Table style={{ width: '100%', textAlign: 'left' }} responsive>
                                                <tbody>
                                                    <tr>
                                                        <div style={{ margin: '10px', border: '2px solid #3FBAC2', padding: '10px', borderRadius: '10px', fontSize: 20 }}>
                                                            <div><strong>Starting Price Per Head: </strong> ${record.starting_price_per_head}</div>
                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                <strong>Seating Capacity: </strong>
                                                                <div className="ml-1">
                                                                    {record.seating_capacity_min}-{record.seating_capacity_max}
                                                                </div>
                                                                <IoPerson className="ml-1" style={{ color: '#3FBAC2' }} />
                                                            </div>                                                            <div><strong>Number of Event Spaces: </strong> {record.number_event_spaces}</div>
                                                            <div><strong>Reception Hall Rental Costs: </strong> {record.starting_cost_reception_hall}</div>
                                                            {/* <div><strong>Reception Hall Rental Notes: </strong> {record.reception_hall_rental_notes}</div> */}
                                                        </div>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                            <h4 className='col-mb-3' style={{ paddingBottom: '10px' }}>
                                                <Button className="btn btn-block flex items-center" style={buttonStyle} onClick={websiteVisit}>
                                                    <FaExternalLinkAlt className="mr-2 h-5 w-5" />
                                                    Visit Vendor Website
                                                </Button>
                                            </h4>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <h4 className='col-mb-3' style={{ paddingTop: '20px' }}>{record.description}</h4>
                                    </Row>
                                    <Row style={{ marginTop: '30px', justifyContent: 'center' }}>
                                        <Col md={10}>
                                            <span style={{ textAlign: 'center' }}>
                                                <h1 style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>Vendor Verified Pricing <a style={{ display: 'flex', alignItems: 'center', marginLeft: '8px' }} data-tooltip-id="my-tooltip" data-tooltip-content="Vendor pricing provided directly to Zenplan">
                                                    <MdVerified style={{ color: "#3FBAC2" }} />
                                                </a> <Tooltip style={{ backgroundColor: "#ABD3FF", color: 'black', fontSize: 20, borderRadius: '10px' }} id="my-tooltip" /></h1>
                                            </span>
                                            <div style={{ position: "relative", overflow: "hidden" }}>
                                                <div style={{ filter: status ? "none" : "blur(10px)" }}>
                                                    <Table striped bordered responsive style={tableStyle}>
                                                        <thead>
                                                            <tr>
                                                                <th style={tableHeadStyle}>Package Type</th>
                                                                <th style={tableHeadStyle}>Package Name</th>
                                                                <th style={tableHeadStyle}>Package Details</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {Object.entries(JSON.parse(record.packages)).map(([packageType, packages]) => (
                                                                packages.map((pkg, index) => (
                                                                    <tr key={index}>
                                                                        <td>{packageType}</td>
                                                                        <td>{pkg.Name}</td>
                                                                        <td>
                                                                            {Object.keys(pkg).filter(key => key !== 'Name').map((detailKey) => (
                                                                                <div key={detailKey}>
                                                                                    <strong>{detailKey}: </strong>
                                                                                    {detailKey.toLowerCase() === 'price per head' ? `$${pkg[detailKey]}` : pkg[detailKey]}
                                                                                </div>
                                                                            ))}
                                                                        </td>
                                                                    </tr>
                                                                ))
                                                            ))}
                                                        </tbody>
                                                    </Table>
                                                </div>
                                                {!status && (
                                                    <div style={{ position: "absolute", top: "1%", left: "50%", transform: "translate(-50%)", backgroundColor: "rgba(255, 255, 255, 0.9)", padding: "10px 20px", borderRadius: "10px", textAlign: "center", zIndex: 10 }}>
                                                        <strong>Please log in to view the full details of the packages</strong><br></br>
                                                        <Link to='/login'>
                                                            <button className='btn btn-block' onClick={handleLoginRedirect} style={navBarButtonStyles}>Login</button>
                                                        </Link>
                                                    </div>
                                                )}
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                        </div>
                    )}

                </div>
            </div>
            <Bottom />
        </div >
    )
}

export default VenueDetail

const tableStyle = {
    marginTop: '10px',
    width: '100%',
    boxShadow: '3px 3px 3px 3px #ccc'
};

const tableHeadStyle = {
    alignItems: 'center',
    textAlign: 'center',
    marginTop: '10px',
    backgroundColor: "#3FBAC2",
    color: 'white',
    fontWeight: 'bold',
    fontSize: 20
};

const buttonStyle = {
    backgroundColor: "#32969C",
    color: 'white',
    fontWeight: 'bold',
    fontSize: 15,
    fontFamily: 'Raleway'
};

const navBarButtonStyles = {
    color: 'white',
    fontSize: 20,
    fontWeight: 'bold',
    backgroundColor: "#3FBAC2",
    fontFamily: 'Raleway',
    marginTop: '2px'
};
