import React, { useState, useContext, useEffect } from 'react';
import NavbarB from './planning_variant/navbar_b.jsx';
import { Table } from 'react-bootstrap';
import { AccountContext } from "./Account";
import { useNavigate } from "react-router-dom";
import 'react-initials-avatar/lib/ReactInitialsAvatar.css';
import UserPool from '../UserPool';
import Bottom from './bottom.jsx';

function Profile() {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [cityRegion, setCityRegion] = useState('');
    const { getSession, logout } = useContext(AccountContext);
    const navigate = useNavigate();

    useEffect(() => {
        if (UserPool.getCurrentUser() == null) {
            console.log('no user found');
            navigate('/login');
        } else {
            getSession().then(({ user }) => {
                user.getUserAttributes(function (err, result) {
                    if (err) {
                        return;
                    } else {
                        let user_data = result
                        for (let i = 0; i < user_data.length; i++) {
                            if (user_data[i].getName() === 'given_name') {
                                setFirstName(user_data[i].getValue());
                            } else if (user_data[i].getName() === 'family_name') {
                                setLastName(user_data[i].getValue());
                            } else if (user_data[i].getName() === 'email') {
                                setEmail(user_data[i].getValue());
                            } else if (user_data[i].getName() === 'phone_number') {
                                setPhoneNumber(user_data[i].getValue());
                            } else if (user_data[i].getName() === 'address') {
                                setCityRegion(user_data[i].getValue());
                            }
                        }
                    }
                });
            });
        }
    }, []);

    const logoutUser = (e) => {
        e.preventDefault();
        logout();
        navigate('/login');
    }

    return (
        <div>
            <NavbarB />
            <div style={{ height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: '50px' }}>
                <div style={{ textAlign: 'center' }}> {/* Centering the text and SVG */}
                    <h1 className='col-mb-3' style={{ fontWeight: 'bold', fontSize: 45 }}>User Profile</h1>
                    <div style={{ marginBottom: '1rem', display: 'flex', justifyContent: 'center' }}>
                        <svg height="150" width="150">
                            <circle cx="75" cy="75" r="70" stroke="black" strokeWidth="2" fill="grey" />
                            <text x="50%" y="50%" textAnchor="middle" fill="#fff" dy=".3em">
                                <tspan x="50%" dy='0.3em' style={{ fontSize: '50px' }}>{firstName.charAt(0)}{lastName.charAt(0)}</tspan>
                            </text>
                        </svg>
                    </div>
                    <Table responsive="sm" style={{ margin: '0 auto' }}>
                        <tbody>
                            <tr>
                                <td style={{ fontWeight: 'bold' }}>Name</td>
                                <td>{firstName} {lastName}</td>
                            </tr>
                            <tr>
                                <td style={{ fontWeight: 'bold' }}>Email</td>
                                <td>{email}</td>
                            </tr>
                            <tr>
                                <td style={{ fontWeight: 'bold' }}>Phone Number</td>
                                <td>{phoneNumber}</td>
                            </tr>
                            <tr>
                                <td style={{ fontWeight: 'bold' }}>City/Region</td>
                                <td>{cityRegion}</td>
                            </tr>
                        </tbody>
                    </Table>
                    <a href="/settings" style={buttonStyle} className="btn btn-block">Settings</a>
                    <button style={buttonStyle} onClick={logoutUser} className="btn btn-block">Logout</button>
                </div>
            </div>

            <Bottom />
        </div>
    );
}

export default Profile;

const buttonStyle = {
    marginTop: '10px',
    backgroundColor: "#3FBAC2",
    color: 'white',
    fontWeight: 'bold',
    fontSize: 25,
    fontFamily: 'Raleway',
    margin: '3px'
};
