import { useState, useContext, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import S1 from '../images/S1-new.jpeg';
import S2 from '../images/S2-new.jpeg';
import screen3 from '../images/Screen-3-new.jpeg';
import CompTable from '../images/CompTable-new.jpeg';
import NavbarB from './planning_variant/navbar_b.jsx';
import Bottom from './bottom.jsx';
import ReactGA from "react-ga4";
import { InlineWidget } from "react-calendly";
import axios from 'axios';
import { AccountContext } from "./Account";
import UserPool from '../UserPool';


function ComingSoon() {
    ReactGA.send({ hitType: "pageview", page: "/comingsoon", title: "Upcoming Features Visit" });

    const [submitted, setSubmitted] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const { getSession } = useContext(AccountContext);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [cityRegion, setCityRegion] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');

    useEffect(() => {
        getSession().then(({ user }) => {
            user.getUserAttributes(function (err, result) {
                if (err) {
                    return;
                } else {
                    let user_data = result
                    for (let i = 0; i < user_data.length; i++) {
                        if (user_data[i].getName() === 'given_name') {
                            setFirstName(user_data[i].getValue());
                        } else if (user_data[i].getName() === 'family_name') {
                            setLastName(user_data[i].getValue());
                        } else if (user_data[i].getName() === 'email') {
                            setEmail(user_data[i].getValue());
                        } else if (user_data[i].getName() === 'phone_number') {
                            setPhoneNumber(user_data[i].getValue());
                        } else if (user_data[i].getName() === 'address') {
                            setCityRegion(user_data[i].getValue());
                        }
                    }
                }
            });
        });
    }, []);

    function joinWaitlist(e) {
        e.preventDefault();

        let request = {
            fullName: `${firstName} ${lastName}`,
            email: email,
            phoneNumber: phoneNumber,
            city_region: cityRegion,
            variant_name: 'Full Planning Solution',
            updated_at: Date(),
            unix_timestamp: Date.now()
        }

        console.log(request);

        axios.post('https://l3sf33aj40.execute-api.us-east-1.amazonaws.com/waitlist', request)
            .then(resp => {
                console.log("Added " + email + " to waitlist:")
                console.log(resp)

                ReactGA.event({
                    category: "Full Planning Variant Signup",
                    action: "Full Planning Variant Signup",
                    label: "Full Planning Variant Signup"
                });

                setSubmitted(true);
            })
            .catch(err => {
                console.log(err);
                setErrorMessage(err.message);
            })
    }

    return (
        <div>
            <NavbarB />
            <div style={{ height: '100%', width: '100%', padding: '50px' }}>
                <div>
                    <div className='container' style={{ padding: '10px' }}>
                        <div style={{ visibility: submitted ? 'hidden' : 'visible', height: submitted ? '1px' : 'auto', justifyContent: 'center', alignItems: 'center', textAlign: 'center', margin: '0 auto' }}>
                            <h1 style={{ fontWeight: 'bold', textAlign: 'center', paddingBottom: '20px', fontSize: 45 }}>
                                Upcoming Features
                            </h1>
                            <Container>
                                <Row>
                                    <Col sm={12} style={{ justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                                        <button className='btn btn-block' style={navBarButtonStyles} onClick={(e) => joinWaitlist(e)}>Are You Interested?</button>
                                    </Col>
                                </Row>
                                <Row style={{ paddingTop: '50px' }}>
                                    <Col sm={4}>
                                        <h2 style={{ fontWeight: 'bold', color: "#32969C" }}>
                                            Zenplan Understands Your Wedding Vision
                                        </h2>
                                        <h3>Seamlessly integrate your Pinterest board and let us know what your wedding vision entails!</h3>
                                    </Col>
                                    <Col sm={8}><img style={imgStyle} src={S1}></img></Col>
                                </Row>
                                <Row style={{ paddingTop: '50px' }}>
                                    <Col sm={4}>
                                        <h2 style={{ fontWeight: 'bold', color: "#32969C" }}>
                                            Effortless Wedding Planning
                                        </h2>
                                        <h3>Say goodbye to wedding planning stress with Zenplan's personalized recommendations and transparent pricing!</h3>
                                    </Col>
                                    <Col sm={8}><img style={imgStyle} src={S2}></img></Col>
                                </Row>
                                <Row style={{ paddingTop: '50px' }}>
                                    <Col sm={4}>
                                        <h2 style={{ fontWeight: 'bold', color: "#32969C" }}>
                                            Say Goodbye To Decision Fatigue
                                        </h2>
                                        <h3>Save time making decisions by easily comparing vendors with Zenplan's generated comparison tables that are based on your personal requirements and vision!</h3>
                                    </Col>
                                    <Col sm={8}><img style={imgStyle} src={CompTable}></img></Col>
                                </Row>
                                <Row style={{ paddingTop: '50px' }}>
                                    <Col sm={4}>
                                        <h2 style={{ fontWeight: 'bold', color: "#32969C" }}>
                                            Efficiently Communicate With Vendors
                                        </h2>
                                        <h3>Stay organized with our centralized communication feature and quickly extract key information using our AI email summaries!</h3>
                                    </Col>
                                    <Col sm={8}><img style={imgStyle} src={screen3}></img></Col>
                                </Row>
                            </Container>
                        </div>
                        <div style={{ visibility: submitted ? 'visible' : 'hidden', height: submitted ? 'auto' : '1px', justifyContent: 'center', alignItems: 'center', textAlign: 'center', margin: '0 auto' }}>
                            <h1>You have been added to the waitlist!</h1>
                            <h3>Please sign up for a free 30 minute call using our Calendly link below, to find out how Zenplan can help you!</h3>

                            <InlineWidget url="https://calendly.com/zenplan-meeting/zenplan-help-you-plan-your-wedding" />

                            <a href='/'><button className="btn btn-block" style={buttonStyle}>Home Page</button></a>
                        </div>
                    </div>
                </div>
            </div>
            <Bottom />
        </div>
    );
}

const imgStyle = {
    width: '100%',
    boxShadow: '5px 5px 5px 5px #ccc',
    borderRadius: '10px'
};

const navBarButtonStyles = {
    color: 'white',
    fontSize: 25,
    fontWeight: 'bold',
    backgroundColor: "#3FBAC2",
    fontFamily: 'Raleway',
    marginTop: '2px'
};

const buttonStyle = {
    marginTop: '50px',
    backgroundColor: "#3FBAC2",
    width: '200px',
    color: 'white',
    fontWeight: 'bold',
    fontSize: 25,
    fontFamily: 'Raleway'
};

export default ComingSoon;