import { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import S1 from '../../images/S1-new.jpeg';
import S2 from '../../images/S2-new.jpeg';
import screen3 from '../../images/Screen-3-new.jpeg';
import CompTable from '../../images/CompTable-new.jpeg';
import screen5 from '../../images/Screen-5.jpeg';
import pricing from '../../images/pricingnew.jpeg';
import searchengine from '../../images/searchengine-new.jpeg';
import { Link } from "react-router-dom";
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import { useMediaQuery } from 'react-responsive';
import { Button, Card } from "flowbite-react";
import venue from '../../images/venue.jpg';
import photo from '../../images/photography.jpg';
import dj from '../../images/dj.jpg';

function FeaturesB() {
    const isMobile = useMediaQuery({ maxWidth: 984 }); // Define your mobile screen width here

    return (
        <div>
            {isMobile ?
                (
                    <div style={{ paddingTop: '20px', paddingBottom: '40px', paddingLeft: '10px', paddingRight: '10px' }}>
                        <h1 style={{ fontWeight: 'bold', fontSize: 25, textAlign: 'center', paddingBottom: '10px' }}>
                            Access <span style={{ color: "#32969C" }}>Vendor Pricing</span> Now
                        </h1>
                        <Container>
                            <Row>
                                <div className="overflow-x-auto flex space-x-4 pb-4">
                                    <Card className="flex-shrink-0 w-72 flex flex-col overflow-hidden p-0" style={{ padding: '0' }}>
                                        <img
                                            src={venue}
                                            alt="Venue"
                                            className="w-full"
                                            style={{ height: '200px', objectFit: 'cover', display: 'block', borderRadius: '3px' }}
                                        />
                                        <div className="flex-grow flex flex-col justify-start">
                                            <h5 className="text-xl font-bold tracking-tight text-gray-900 dark:text-white text-center m-0 mb-1">
                                                Wedding Venues
                                            </h5>
                                            <p className="font-normal text-gray-700 dark:text-gray-400 text-left m-0 mb-1">
                                                Access wedding venue pricing and package information to help you book the best venue for your big day!
                                            </p>
                                            <div className="mt-auto">
                                                <Button className="mt-2 bg-zenplanColour">
                                                    <Link style={{ color: 'white', textDecoration: 'none' }} to='/venues'>
                                                        Explore Venues
                                                    </Link>
                                                </Button>
                                            </div>
                                        </div>
                                    </Card>
                                    <Card className="flex-shrink-0 w-72 flex flex-col overflow-hidden p-0" style={{ padding: '0' }}>
                                        <img
                                            src={photo}
                                            alt="photography"
                                            className="w-full"
                                            style={{ height: '200px', objectFit: 'cover', display: 'block', borderRadius: '3px' }}
                                        />
                                        <div className="flex-grow flex flex-col justify-start">
                                            <h5 className="text-xl font-bold tracking-tight text-gray-900 dark:text-white text-center m-0 mb-1">
                                                Wedding Photographers
                                            </h5>
                                            <p className="font-normal text-gray-700 dark:text-gray-400 text-left m-0 mb-1">
                                                Access wedding photography pricing and package information to help you capture your wedding day story!
                                            </p>
                                            <div className="mt-auto">
                                                <Button className="mt-2 bg-zenplanColour">
                                                    <Link style={{ color: 'white', textDecoration: 'none' }} to='/photographers'>
                                                        Explore Photographers
                                                    </Link>
                                                </Button>
                                            </div>
                                        </div>
                                    </Card>
                                    <Card className="flex-shrink-0 w-72 flex flex-col overflow-hidden p-0" style={{ padding: '0' }}>
                                        <img
                                            src={dj}
                                            alt="dj"
                                            className="w-full"
                                            style={{ height: '200px', objectFit: 'cover', display: 'block', borderRadius: '3px' }}
                                        />
                                        <div className="flex-grow flex flex-col justify-start">
                                            <h5 className="text-xl font-bold tracking-tight text-gray-900 dark:text-white text-center m-0 mb-1">
                                                Wedding DJs
                                            </h5>
                                            <p className="font-normal text-gray-700 dark:text-gray-400 text-left m-0 mb-1">
                                                Access wedding dj pricing and package information to find the best music to dance the night away!
                                            </p>
                                            <div className="mt-auto">
                                                <Button className="mt-2 bg-zenplanColour">
                                                    <Link style={{ color: 'white', textDecoration: 'none' }} to='/djs'>
                                                        Explore DJs
                                                    </Link>
                                                </Button>
                                            </div>
                                        </div>
                                    </Card>
                                </div>
                            </Row>
                            <Row style={{ paddingTop: '50px' }}>
                                <Col sm={4}>
                                    <h2 style={mobileFeatureTiteStyle}>
                                        Easily Find Your Favourite Vendors and Details
                                    </h2>
                                    <h3 style={mobileFeatureBodyStyle}>Quickly view pricing, ratings, and all other information you need by using our initiative filtering capabilities!</h3>
                                </Col>
                                <Col sm={8}><img style={imgStyle} src={searchengine}></img></Col>
                            </Row>
                            <Row style={{ paddingTop: '50px' }}>
                                <Col sm={4}>
                                    <h2 style={mobileFeatureTiteStyle}>
                                        Transparent Pricing
                                    </h2>
                                    <h3 style={mobileFeatureBodyStyle}>Say hello to clarity and peace of mind with transparent pricing from user reported submissions or direct vendor uploaded information!</h3>
                                </Col>
                                <Col sm={8}><img style={imgStyle} src={pricing}></img></Col>
                            </Row>

                            <h1 style={{ fontSize: '28px', fontWeight: 'bold', textAlign: 'center', paddingBottom: '10px', paddingTop: '80px' }}>
                                Upcoming Features
                            </h1>
                            <Row>
                                <Col sm={12} style={{ justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                                    <ButtonGroup style={{ marginBottom: '2px', marginRight: '5px' }}>
                                        <a href='/couples_waitlist'><button className="btn btn-block" style={mobileLandingPageCTAButton}>Join New Features Waitlist</button></a>
                                    </ButtonGroup>
                                </Col>
                            </Row>
                            <Row style={{ paddingTop: '20px' }}>
                                <Col sm={4}>
                                    <h2 style={mobileFeatureTiteStyle}>
                                        Zenplan Understands Your Wedding Vision
                                    </h2>
                                    <h3 style={mobileFeatureBodyStyle}>Seamlessly integrate your Pinterest board and let us know what your wedding vision entails!</h3>
                                </Col>
                                <Col sm={8}><img style={imgStyle} src={S1}></img></Col>
                            </Row>
                            <Row style={{ paddingTop: '50px' }}>
                                <Col sm={4}>
                                    <h2 style={mobileFeatureTiteStyle}>
                                        Effortless Wedding Planning
                                    </h2>
                                    <h3 style={mobileFeatureBodyStyle}>Say goodbye to wedding planning stress with Zenplan's personalized recommendations and transparent pricing!</h3>
                                </Col>
                                <Col sm={8}><img style={imgStyle} src={S2}></img></Col>
                            </Row>
                            <Row style={{ paddingTop: '50px' }}>
                                <Col sm={4}>
                                    <h2 style={mobileFeatureTiteStyle}>
                                        Say Goodbye To Decision Fatigue
                                    </h2>
                                    <h3 style={mobileFeatureBodyStyle}>Save time making decisions by easily comparing vendors with Zenplan's generated comparison tables that are based on your personal requirements and vision!</h3>
                                </Col>
                                <Col sm={8}><img style={imgStyle} src={CompTable}></img></Col>
                            </Row>
                            <Row style={{ paddingTop: '50px' }}>
                                <Col sm={4}>
                                    <h2 style={mobileFeatureTiteStyle}>
                                        Efficiently Communicate With Vendors
                                    </h2>
                                    <h3 style={mobileFeatureBodyStyle}>Stay organized with our centralized communication feature and quickly extract key information using our AI email summaries!</h3>
                                </Col>
                                <Col sm={8}><img style={imgStyle} src={screen3}></img></Col>
                            </Row>
                        </Container>
                    </div>
                ) :
                (
                    <div style={{ padding: '40px' }}>
                        <h1 style={{ fontWeight: 'bold', textAlign: 'center', paddingBottom: '20px' }}>
                            Access <span style={{ color: "#32969C" }}>Vendor Pricing</span> Now
                        </h1>
                        <Container>
                            <Row>
                                <div className="grid grid-cols-3 gap-4">
                                    <Card className="max-w-sm flex flex-col overflow-hidden p-0 min-w-full" style={{ padding: '0' }}>
                                        <img
                                            src={venue}
                                            alt="Venue"
                                            className="w-full"
                                            style={{ height: '200px', objectFit: 'cover', display: 'block', borderRadius: '3px' }}
                                        />
                                        <div className="flex-grow flex flex-col justify-start">
                                            <h5 className="text-xl font-bold tracking-tight text-gray-900 dark:text-white text-center m-0 mb-1">
                                                Wedding Venues
                                            </h5>
                                            <p className="font-normal text-gray-700 dark:text-gray-400 text-left m-0 mb-1">
                                                Access wedding venue pricing and package information to help you book the best venue for your big day!
                                            </p>
                                            <div className="mt-auto">
                                                <Button className="mt-2 bg-zenplanColour">
                                                    <Link style={{ color: 'white', textDecoration: 'none' }} to='/venues'>
                                                        Explore Venues
                                                    </Link>
                                                </Button>
                                            </div>
                                        </div>
                                    </Card>
                                    <Card className="max-w-sm flex flex-col overflow-hidden p-0 min-w-full" style={{ padding: '0' }}>
                                        <img
                                            src={photo}
                                            alt="photography"
                                            className="w-full"
                                            style={{ height: '200px', objectFit: 'cover', display: 'block', borderRadius: '3px' }}
                                        />
                                        <div className="flex-grow flex flex-col justify-start">
                                            <h5 className="text-xl font-bold tracking-tight text-gray-900 dark:text-white text-center m-0 mb-1">
                                                Wedding Photographers
                                            </h5>
                                            <p className="font-normal text-gray-700 dark:text-gray-400 text-left m-0 mb-1">
                                                Access wedding photography pricing and package information to help you capture your wedding day story!
                                            </p>
                                            <div className="mt-auto">
                                                <Button className="mt-2 bg-zenplanColour">
                                                    <Link style={{ color: 'white', textDecoration: 'none' }} to='/photographers'>
                                                        Explore Photographers
                                                    </Link>
                                                </Button>
                                            </div>
                                        </div>
                                    </Card>
                                    <Card className="max-w-sm flex flex-col overflow-hidden p-0 min-w-full" style={{ padding: '0' }}>
                                        <img
                                            src={dj}
                                            alt="dj"
                                            className="w-full"
                                            style={{ height: '200px', objectFit: 'cover', display: 'block', borderRadius: '3px' }}
                                        />
                                        <div className="flex-grow flex flex-col justify-start">
                                            <h5 className="text-xl font-bold tracking-tight text-gray-900 dark:text-white text-center m-0 mb-1">
                                                Wedding DJs
                                            </h5>
                                            <p className="font-normal text-gray-700 dark:text-gray-400 text-left m-0 mb-1">
                                                Access wedding dj pricing and package information to find the best music to dance the night away!
                                            </p>
                                            <div className="mt-auto">
                                                <Button className="mt-2 bg-zenplanColour">
                                                    <Link style={{ color: 'white', textDecoration: 'none' }} to='/djs'>
                                                        Explore DJs
                                                    </Link>
                                                </Button>
                                            </div>
                                        </div>
                                    </Card>
                                </div>
                            </Row>
                            <Row style={{ paddingTop: '50px' }}>
                                <Col sm={4}>
                                    <h2 style={{ fontWeight: 'bold', color: "#32969C" }}>
                                        Easily Find Your Favourite Vendors and Details
                                    </h2>
                                    <h3>Quickly view pricing, ratings, and all other information you need by using our initiative filtering capabilities!</h3>
                                </Col>
                                <Col sm={8}><img style={imgStyle} src={searchengine}></img></Col>
                            </Row>
                            <Row style={{ paddingTop: '50px' }}>
                                <Col sm={4}>
                                    <h2 style={{ fontWeight: 'bold', color: "#32969C" }}>
                                        Transparent Pricing
                                    </h2>
                                    <h3>Say hello to clarity and peace of mind with transparent pricing from user reported submissions or direct vendor uploaded information!</h3>
                                </Col>
                                <Col sm={8}><img style={imgStyle} src={pricing}></img></Col>
                            </Row>

                            <h1 style={{ fontWeight: 'bold', textAlign: 'center', paddingBottom: '20px', paddingTop: '40px' }}>
                                Upcoming Features
                            </h1>
                            <Row>
                                <Col sm={12} style={{ justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                                    <ButtonGroup style={{ marginBottom: '2px', marginRight: '5px' }}>
                                        <a href='/couples_waitlist'><button className="btn btn-block" style={navBarButtonStyles}>Join New Features Waitlist</button></a>
                                    </ButtonGroup>
                                </Col>
                            </Row>
                            <Row style={{ paddingTop: '50px' }}>
                                <Col sm={4}>
                                    <h2 style={{ fontWeight: 'bold', color: "#32969C" }}>
                                        Zenplan Understands Your Wedding Vision
                                    </h2>
                                    <h3>Seamlessly integrate your Pinterest board and let us know what your wedding vision entails!</h3>
                                </Col>
                                <Col sm={8}><img style={imgStyle} src={S1}></img></Col>
                            </Row>
                            <Row style={{ paddingTop: '50px' }}>
                                <Col sm={4}>
                                    <h2 style={{ fontWeight: 'bold', color: "#32969C" }}>
                                        Effortless Wedding Planning
                                    </h2>
                                    <h3>Say goodbye to wedding planning stress with Zenplan's personalized recommendations and transparent pricing!</h3>
                                </Col>
                                <Col sm={8}><img style={imgStyle} src={S2}></img></Col>
                            </Row>
                            <Row style={{ paddingTop: '50px' }}>
                                <Col sm={4}>
                                    <h2 style={{ fontWeight: 'bold', color: "#32969C" }}>
                                        Say Goodbye To Decision Fatigue
                                    </h2>
                                    <h3>Save time making decisions by easily comparing vendors with Zenplan's generated comparison tables that are based on your personal requirements and vision!</h3>
                                </Col>
                                <Col sm={8}><img style={imgStyle} src={CompTable}></img></Col>
                            </Row>
                            <Row style={{ paddingTop: '50px' }}>
                                <Col sm={4}>
                                    <h2 style={{ fontWeight: 'bold', color: "#32969C" }}>
                                        Efficiently Communicate With Vendors
                                    </h2>
                                    <h3>Stay organized with our centralized communication feature and quickly extract key information using our AI email summaries!</h3>
                                </Col>
                                <Col sm={8}><img style={imgStyle} src={screen3}></img></Col>
                            </Row>
                        </Container>
                    </div>
                )
            }
        </div>
    );
}

const imgStyle = {
    width: '100%',
    boxShadow: '5px 5px 5px 5px #ccc',
    borderRadius: '10px'
};

const navBarButtonStyles = {
    color: 'white',
    fontSize: 25,
    fontWeight: 'bold',
    backgroundColor: "#3FBAC2",
    fontFamily: 'Raleway',
    marginTop: '2px'
};

const mobileLandingPageCTAButton = {
    color: 'white',
    fontSize: 20,
    fontWeight: 'bold',
    backgroundColor: "#3FBAC2",
    fontFamily: 'Raleway',
    marginTop: '2px'
};

const mobileFeatureTiteStyle = {
    fontWeight: 'bold',
    fontSize: 20,
    color: "#32969C"
};

const mobileFeatureBodyStyle = {
    fontSize: 16,
};

export default FeaturesB;