import React, { useState, useContext, useEffect } from 'react';
import logo from '../images/logo.png';
import showPwdImg from '../images/show-password.svg';
import hidePwdImg from '../images/hide-password.svg';
import NavbarB from './planning_variant/navbar_b.jsx';
import { AccountContext } from "./Account";
import UserPool from '../UserPool';
import { useLocation, useNavigate } from "react-router-dom";
import { CognitoUser } from "amazon-cognito-identity-js";
import Bottom from './bottom.jsx';

function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isRevealPwd, setIsRevealPwd] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const location = useLocation();
    const navigate = useNavigate();

    const { authenticate, getSession } = useContext(AccountContext);

    useEffect(() => {
        getSession()
            .then(session => {
                console.log("Session: ", session)
                navigate('/profile');
            })
    }, []);

    const login = (e) => {
        e.preventDefault();

        authenticate(email, password)
            .then(data => {
                console.log("Logged in!", data)
                setErrorMessage('');
                setTimeout(function () {
                    // After successful login
                    const redirectTo = localStorage.getItem('redirectAfterLogin') || '/';
                    localStorage.removeItem('redirectAfterLogin'); // Clean up after use
                    console.log('Redirecting to:', decodeURIComponent(redirectTo));
                    navigate(decodeURIComponent(redirectTo), { replace: true });
                }, 100);
            })
            .catch(err => {
                if (err.name === "UserNotConfirmedException") {
                    const userData = {
                        Username: email,
                        Pool: UserPool
                    };

                    const cognitoUser = new CognitoUser(userData);

                    cognitoUser.resendConfirmationCode((err, result) => {
                        if (err) {
                            console.log('error', err.message);
                        }
                        console.log('call result: ' + JSON.stringify(result));
                    });
                    setTimeout(function () {
                        navigate('/confirm', { state: { email: email } });
                    }, 500);
                } else {
                    console.log("Failed to login!", err)
                    setErrorMessage(err.message);
                }
            })
    };

    return (
        <div>
            <NavbarB />
            <div style={{ height: '100%', width: '100%', padding: '50px' }}>
                <div>
                    <div className='container' style={{ padding: '10px' }}>
                        <div style={{ height: 'auto', justifyContent: 'center', alignItems: 'center', textAlign: 'center', margin: '0 auto' }}>
                            <h1 className='col-mb-3' style={{ fontWeight: 'bold', fontSize: 45 }}>Login</h1>
                            <form onSubmit={login} style={{ display: 'inline-block', alignItems: 'center', textAlign: 'center', marginTop: '10px' }}>
                                <div className="form-group" style={{ paddingBottom: '10px' }}>
                                    <label style={{ fontSize: 25 }} for="email">Email address</label>
                                    <input style={{ border: '1px solid' }} value={email} type="email" id="email" className="form-control" placeholder="Enter email" onChange={(event) => setEmail(event.target.value)} required />
                                </div>

                                <div className="form-group" style={{ position: 'relative', paddingBottom: '10px' }}>
                                    <label style={{ fontSize: 25 }} for="password">Password</label>
                                    <input value={password} style={{ padding: '5px 30px 5px 10px', border: '1px solid' }} type={isRevealPwd ? "text" : "password"} id="password" className="form-control" placeholder="Enter password" onChange={(event) => setPassword(event.target.value)} required />
                                    <img
                                        style={{ width: '20px', position: 'absolute', right: '1rem', top: '3rem' }}
                                        title={isRevealPwd ? "Hide password" : "Show password"}
                                        src={isRevealPwd ? hidePwdImg : showPwdImg}
                                        onClick={() => setIsRevealPwd(prevState => !prevState)}
                                        alt="Hide/Show password"
                                    />
                                    <div style={{ float: 'left', color: 'red', fontSize: '20px' }}>{errorMessage}</div>
                                </div>
                                <button type="submit" className="btn btn-block" style={buttonStyle}>Log In</button>
                                <p className="forgot-password text-right" style={{ paddingTop: '10px', textAlign: 'center' }}>
                                    <a href="/forgot_password">Forgot password?</a>
                                </p>
                                <h5 style={{ textAlign: 'center' }}>New to Zenplan?</h5>
                                <a href="/signup" className="btn btn-block" style={buttonStyle}>Create your Zenplan account</a>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <Bottom />
        </div>
    );
}

export default Login;

const buttonStyle = {
    marginTop: '10px',
    backgroundColor: "#3FBAC2",
    color: 'white',
    fontWeight: 'bold',
    fontSize: 25,
    fontFamily: 'Raleway'
};
