import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import logo from '../images/logo.png';
import NavbarB from './planning_variant/navbar_b.jsx';
import UserPool from '../UserPool';
import { CognitoUser } from 'amazon-cognito-identity-js';
import Bottom from './bottom.jsx';

function ConfirmSignup() {
    const [code, setCode] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [resendCodeErrorMessage, setResendCodeErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [resendCodeSuccessMessage, setResendCodeSuccessMessage] = useState('');
    const location = useLocation();
    const navigate = useNavigate();

    const confirm = (e) => {
        e.preventDefault();
        const userData = {
            Username: location.state.email,
            Pool: UserPool
        };

        const cognitoUser = new CognitoUser(userData);

        setResendCodeErrorMessage('');
        cognitoUser.confirmRegistration(code, true, (err, result) => {
            if (err) {
                console.log('error', err.message);
                setErrorMessage(err.message);
                return;
            }
            console.log('call result: ' + JSON.stringify(result));
            setErrorMessage('');
            setSuccessMessage("Success! Navigating to Login");
            setTimeout(function () {
                navigate('/login');
            }, 1000);
        });
    };

    const resendVerification = (e) => {
        e.preventDefault();
        const userData = {
            Username: location.state.email,
            Pool: UserPool
        };

        const cognitoUser = new CognitoUser(userData);

        cognitoUser.resendConfirmationCode((err, result) => {
            if (err) {
                console.log('error', err.message);
                setResendCodeErrorMessage(err.message);
                return;
            }
            console.log('call result: ' + JSON.stringify(result));
            setResendCodeErrorMessage('');
            setResendCodeSuccessMessage("Verification Code Resent!");
        });
    };

    return (
        <div>
            <NavbarB />
            <div style={{ height: '100%', width: '100%', padding: '50px' }}>
                <div>
                    <div className='container' style={{ padding: '10px' }}>
                        <div style={{ height: 'auto', justifyContent: 'center', alignItems: 'center', textAlign: 'center', margin: '0 auto' }}>
                            <h1 className='col-mb-3' style={{ fontWeight: 'bold', fontSize: 45 }}>Validate Account</h1>
                            <h4 style={{ textAlign: 'center' }}>A verification code has been sent to your email</h4>
                            <form onSubmit={confirm} style={{ display: 'inline-block', alignItems: 'center', textAlign: 'center', marginTop: '10px' }}>
                                <div className="form-group" style={{ paddingBottom: '10px' }}>
                                    <label style={{ fontSize: 25 }} for="code">Verification Code</label>
                                    <input style={{ border: '1px solid' }} value={code} type="text" id="code" className="form-control" placeholder="Enter verification code" onChange={(event) => setCode(event.target.value)} required />
                                    <div style={{ float: 'left', color: 'red', fontSize: '20px' }}>{errorMessage}</div>
                                    <div style={{ float: 'left', color: 'green', fontSize: '20px' }}>{successMessage}</div>
                                </div>

                                <button style={buttonStyle} type="submit" className="btn btn-block">Confirm</button>
                                <p style={{ paddingTop: '3%', fontSize: 20 }}>Didn't receive a code? <button onClick={resendVerification} style={buttonStyle} className="btn btn-block">Resend Code</button></p>
                                <div style={{ textAlign: 'center', color: 'red', fontSize: '20px' }}>{resendCodeErrorMessage}</div>
                                <div style={{ textAlign: 'center', color: 'green', fontSize: '20px' }}>{resendCodeSuccessMessage}</div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <Bottom />
        </div>
    );
}

export default ConfirmSignup;

const buttonStyle = {
    marginTop: '10px',
    backgroundColor: "#3FBAC2",
    color: 'white',
    fontWeight: 'bold',
    fontSize: 25,
    fontFamily: 'Raleway'
};
