import React, { useState, useMemo, useEffect, useContext, useCallback } from 'react';
import { useReactTable, getCoreRowModel, flexRender, getPaginationRowModel, getSortedRowModel, getFilteredRowModel } from '@tanstack/react-table';
import { IoPerson } from "react-icons/io5";
import { AiOutlineHeart, AiFillHeart } from 'react-icons/ai';
import { Link } from "react-router-dom";
import StarRatings from "react-star-ratings";
import { useMediaQuery } from 'react-responsive';
import axios from 'axios';
import { Button, Card } from "flowbite-react";
import ClipLoader from "react-spinners/ClipLoader";
import NavbarB from './planning_variant/navbar_b.jsx';
import ReactGA from "react-ga4";
import Bottom from './bottom.jsx';
import { AccountContext } from "./Account";
import UserPool from '../UserPool';
import { useNavigate } from 'react-router-dom';

function VenueFavs() {
    const [records, setRecords] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [globalFilter, setGlobalFilter] = useState('');
    const [sorting, setSorting] = useState([]);
    const [columnFilters, setColumnFilters] = useState([]);
    const [favourites, setFavourites] = useState(new Set());  // State for managing user favourites
    const { getSession } = useContext(AccountContext);
    const [userID, setUserID] = useState(null);
    const navigate = useNavigate();
    const [venueIds, setVenueIds] = useState([]);

    ReactGA.send({ hitType: "pageview", page: "/venue-favourites", title: "Venue Favourites Visit" });

    const isMobile = useMediaQuery({ maxWidth: 984 }); // Define your mobile screen width here
    const data = useMemo(() => records ?? [], [records])

    // Function to fetch venue records based on IDs
    const fetchVenueRecords = useCallback(async (ids) => {
        if (ids.length === 0) {
            setRecords([]); // Clear records if no IDs
            return;
        }

        try {
            const response = await axios.post('https://a7okqrhxf9.execute-api.us-east-1.amazonaws.com/items/multiple', {
                ids: ids,
            });
            console.log('Venue records response:', response.data);
            setRecords(response.data);
        } catch (error) {
            console.error('Error fetching venue records:', error);
            setErrorMessage(error.message);
        }
    }, []);

    // Function to fetch user's favorites and venue records
    const fetchData = useCallback(async () => {
        try {
            if (userID) {
                // Fetch user's favorite venues
                const favsResponse = await axios.get(`https://bt5udcg0qk.execute-api.us-east-1.amazonaws.com/favourites?userID=${userID}`);
                const userFavorites = new Set(favsResponse.data.venueFavs);
                setFavourites(userFavorites);

                // Fetch venue records based on user's favorite IDs
                const venueFavIds = Array.from(userFavorites);
                setVenueIds(venueFavIds);
                fetchVenueRecords(venueFavIds);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            setErrorMessage(error.message);
        }
    }, [userID, getSession, fetchVenueRecords]);

    // Get userID on component mount
    useEffect(() => {
        const fetchUserID = async () => {
            if (UserPool.getCurrentUser() == null) {
                setUserID(null);
            } else {
                const session = await getSession();
                setUserID(String(session.user.username));
            }
        };
        fetchUserID();
    }, [getSession]);

    // Fetch data when userID changes
    useEffect(() => {
        if (userID) {
            fetchData();
        }
    }, [userID, fetchData]);

    // Handle favorite click
    const handleFavouriteClick = async (vendorId) => {
        if (!userID) {
            navigate('/login');
            return;
        }

        const updatedFavourites = new Set(favourites);
        if (updatedFavourites.has(vendorId)) {
            updatedFavourites.delete(vendorId);
        } else {
            updatedFavourites.add(vendorId);
        }
        setFavourites(updatedFavourites);

        try {
            await axios.post('https://bt5udcg0qk.execute-api.us-east-1.amazonaws.com/favourites', {
                userID,
                updatedFavourites: Array.from(updatedFavourites),
            });

            console.log('Favorite updated successfully.');

            // Refresh the venue records to reflect the new favorites
            const updatedVenueIds = Array.from(updatedFavourites);
            setVenueIds(updatedVenueIds);

            if (updatedVenueIds.length > 0) {
                fetchVenueRecords(updatedVenueIds);
            } else {
                setRecords([]); // Clear records if no favorites
            }
        } catch (error) {
            console.error('Error updating favorites:', error);
        }
    };


    const columns = [
        {
            header: 'Image',
            accessorKey: 's3_path',
            footer: 'Image',
            enableSorting: false
        },
        {
            header: 'Venue Name',
            accessorKey: 'name',
            footer: 'Venue Name'
        },
        {
            header: 'Location',
            accessorKey: 'location',
            footer: 'Location'
        },
        {
            header: 'Starting Price Per Head',
            accessorKey: 'starting_price_per_head',
            footer: 'Starting Price Per Head',
            cell: (info) => {
                return (
                    <div>
                        {isMobile ?
                            (
                                <div>
                                    <strong>Starting Price Per Head: </strong>{"$" + info.getValue()}
                                </div>
                            ) :
                            (
                                <div>
                                    {"$" + info.getValue()}
                                </div>
                            )
                        }
                    </div>
                )
            }
        },
        {
            header: 'Seating Capacity',
            accessorKey: 'seating_capacity_max',
            footer: 'Seating Capacity',
            cell: ({ row }) => {
                const rowData = row.original

                return (
                    <div>
                        {isMobile ?
                            (
                                <div>
                                    <strong>Seating Capacity: </strong>{rowData.seating_capacity_min}-{rowData.seating_capacity_max} <IoPerson style={{ color: '#3FBAC2' }} />
                                </div>
                            ) :
                            (
                                <div>
                                    {rowData.seating_capacity_min}-{rowData.seating_capacity_max} <IoPerson style={{ color: '#3FBAC2' }} />
                                </div>
                            )
                        }
                    </div>
                )
            }
        },
        {
            header: 'Rating',
            accessorKey: 'review_rating',
            footer: 'Rating',
            cell: ({ row }) => {
                const rowData = row.original

                return (
                    <div>
                        <div style={{ color: '#3FBAC2' }}>{rowData.review_rating} Stars</div>
                        <StarRatings
                            rating={rowData.review_rating}
                            starRatedColor="#3FBAC2"
                            numberOfStars={5}
                            name='rating'
                            starDimension="15px"
                            starSpacing='1px'
                        />
                        <br></br>
                        <div style={{ color: '#3FBAC2' }}>{rowData.number_of_reviews} Reviews</div>
                    </div>
                );
            }
        },
        {
            id: "actions",
            cell: ({ row }) => {
                const rowData = row.original

                return (
                    <button className="btn btn-block" style={buttonStyle}><Link style={{ color: 'white', textDecoration: 'none' }} to={`/venues/${rowData.id}`} state={rowData}>Vendor Details</Link></button>
                )
            }
        }
    ]

    const truncateText = (text, wordLimit) => {
        const words = text.split(" ");
        if (words.length > wordLimit) {
            return words.slice(0, wordLimit).join(" ") + "...";
        }
        return text;
    };

    const table = useReactTable({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        state: {
            sorting,
            columnFilters,
            globalFilter,
        },
        onSortingChange: setSorting,
        onColumnFiltersChange: setColumnFilters,
        onGlobalFilterChange: setGlobalFilter,
        initialState: {
            pagination: {
                pageSize: 9,
            },
        },
    });

    return (
        <div>
            <NavbarB />
            <div style={{ height: '100%', width: '100%' }}>
                <div>
                    {isMobile ?
                        (
                            <div className='container' style={{ paddingTop: '15px', paddingBottom: '15px' }}>
                                <div style={{ height: 'auto', justifyContent: 'center', alignItems: 'center', textAlign: 'center', margin: '0 auto' }}>
                                    <h1 className='col-mb-3' style={{ fontWeight: 'bold', fontSize: 30, paddingBottom: '2px' }}>Venue Favourites</h1>
                                </div>
                            </div>
                        ) :
                        (
                            <div className='container' style={{ paddingTop: '15px', paddingBottom: '15px' }}>
                                <div style={{ height: 'auto', justifyContent: 'center', alignItems: 'center', textAlign: 'center', margin: '0 auto' }}>
                                    <h1 className='col-mb-3' style={{ fontWeight: 'bold', fontSize: 45, paddingBottom: '2px' }}>Venue Favourites</h1>
                                </div>
                            </div>
                        )
                    }
                    <div className='container' style={{ padding: '5px' }}>
                        <div style={{ height: 'auto', justifyContent: 'center', alignItems: 'center', textAlign: 'center', margin: '0 auto' }}>
                            {isMobile ? (
                                data.length === 0 ? (
                                    <ClipLoader color="#32969C" size={75} />
                                ) : (
                                    <div className="mt-2 grid grid-cols-1 gap-4 justify-items-center">
                                        {table.getRowModel().rows.map(row => {
                                            const rowData = row.original;
                                            const isFavourite = favourites.has(rowData.id);
                                            return (
                                                <Card key={row.id} className="max-w-sm w-full flex flex-col items-center relative" >
                                                    <div className="relative w-full">
                                                        <img src={`https://d21i5s43fw3pjx.cloudfront.net/${rowData.s3_path}`} alt="Venue" className="w-full h-auto object-cover" />
                                                        <button onClick={() => handleFavouriteClick(rowData.id)} className="absolute top-2 right-2 p-1 bg-white rounded-full" style={{ backgroundColor: 'rgba(255, 255, 255, 1)' }}>
                                                            {isFavourite ? <AiFillHeart color="#3FBAC2" size={24} /> : <AiOutlineHeart color="#3FBAC2" size={24} />}
                                                        </button>
                                                    </div>
                                                    <div className="p-2 flex flex-col items-start">
                                                        <h5
                                                            className="font-bold tracking-tight text-gray-900 dark:text-white text-left mb-2"
                                                            style={{ fontSize: 20 }}
                                                        >
                                                            {rowData.name}
                                                        </h5>
                                                        <p className="font-normal text-gray-700 dark:text-gray-400 text-left mb-2">
                                                            {rowData.location}
                                                        </p>
                                                        <p className="font-normal text-gray-700 dark:text-gray-400 text-left mb-2">
                                                            {rowData.review_rating}
                                                            <StarRatings
                                                                rating={rowData.review_rating}
                                                                starRatedColor="#3FBAC2"
                                                                numberOfStars={5}
                                                                name="rating"
                                                                starDimension="15px"
                                                                starSpacing="1px"
                                                            />{" "}
                                                            ({rowData.number_of_reviews} reviews)
                                                        </p>
                                                        <p className="font-normal text-gray-700 dark:text-gray-400 text-left mb-2">
                                                            <strong>Starting Price Per Head: </strong>
                                                            {"$" + rowData.starting_price_per_head}
                                                        </p>
                                                        <p className="font-normal text-gray-700 dark:text-gray-400 text-left">
                                                            {truncateText(rowData.description, 25)}
                                                        </p>
                                                        <div className="font-normal text-gray-700 dark:text-gray-400 flex items-center text-left mb-2">
                                                            <strong>Seating Capacity: </strong>
                                                            <span className="ml-1">
                                                                {rowData.seating_capacity_min}-{rowData.seating_capacity_max}
                                                            </span>
                                                            <IoPerson className="ml-2" style={{ color: "#3FBAC2" }} />
                                                        </div>
                                                        <Button className="mt-4 bg-zenplanColour">
                                                            <Link
                                                                style={{ color: "white", textDecoration: "none" }}
                                                                to={`/venues/${rowData.id}`}
                                                                state={rowData}
                                                            >
                                                                Vendor Details
                                                            </Link>
                                                        </Button>
                                                    </div>
                                                </Card>
                                            );
                                        })}
                                    </div>
                                )
                            ) : (
                                data.length === 0 ? (
                                    <ClipLoader color="#32969C" size={75} />
                                ) : (
                                    <div className="grid grid-cols-3 gap-4">
                                        {table.getRowModel().rows.map(row => {
                                            const rowData = row.original;
                                            const isFavourite = favourites.has(rowData.id);
                                            return (
                                                <Card key={row.id} className="max-w-sm flex flex-col overflow-hidden p-0" style={{ padding: '0' }}>
                                                    <div className="relative w-full h-[300px]">
                                                        <img src={`https://d21i5s43fw3pjx.cloudfront.net/${rowData.s3_path}`} alt="Venue" className="w-full" style={{ height: '300px', objectFit: 'cover', display: 'block', borderRadius: '3px' }} />
                                                        <button onClick={() => handleFavouriteClick(rowData.id)} className="absolute top-1 right-1 p-1 rounded-full" style={{ backgroundColor: 'rgba(255, 255, 255, 1)' }} >
                                                            {isFavourite ? <AiFillHeart color="#3FBAC2" size={25} /> : <AiOutlineHeart color="#3FBAC2" size={25} />}
                                                        </button>
                                                    </div>
                                                    <div className="flex-grow flex flex-col justify-start">
                                                        <h5 className="text-xl font-bold tracking-tight text-gray-900 dark:text-white text-left m-0 mb-1">
                                                            {rowData.name}
                                                        </h5>
                                                        <p className="font-normal text-gray-700 dark:text-gray-400 text-left m-0 mb-1">
                                                            {rowData.location}
                                                        </p>
                                                        <p className="font-normal text-gray-700 dark:text-gray-400 text-left m-0 mb-1">
                                                            {rowData.review_rating}
                                                            <StarRatings
                                                                rating={rowData.review_rating}
                                                                starRatedColor="#3FBAC2"
                                                                numberOfStars={5}
                                                                name="rating"
                                                                starDimension="15px"
                                                                starSpacing="1px"
                                                            /> ({rowData.number_of_reviews} reviews)
                                                        </p>
                                                        <p className="font-normal text-gray-700 dark:text-gray-400 text-left m-0 mb-1">
                                                            <strong>Starting Price Per Head: </strong>{"$" + rowData.starting_price_per_head}
                                                        </p>
                                                        <p className="font-normal text-gray-700 dark:text-gray-400 text-left">
                                                            {truncateText(rowData.description, 25)}
                                                        </p>
                                                        <div className="font-normal text-gray-700 dark:text-gray-400 flex items-center text-left m-0 mb-1">
                                                            <strong>Seating Capacity: </strong>
                                                            <span className="ml-1">
                                                                {rowData.seating_capacity_min}-{rowData.seating_capacity_max}
                                                            </span>
                                                            <IoPerson className="ml-2" style={{ color: '#3FBAC2' }} />
                                                        </div>
                                                        <div className="mt-auto">
                                                            <Button className="mt-4 bg-zenplanColour">
                                                                <Link style={{ color: 'white', textDecoration: 'none' }} to={`/venues/${rowData.id}`} state={rowData}>
                                                                    Vendor Details
                                                                </Link>
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </Card>
                                            );
                                        })}
                                    </div>
                                )
                            )}
                            <div className='mt-2 mb-4'>
                                <button className="btn btn-block" style={paginationButtonStyle} disabled={!table.getCanPreviousPage()} onClick={() => table.previousPage()}>
                                    Previous
                                </button>
                                <button className="btn btn-block" style={paginationButtonStyle} disabled={!table.getCanNextPage()} onClick={() => table.nextPage()}>
                                    Next
                                </button>
                                <span className="flex items-center justify-center gap-1">
                                    <div style={{ marginTop: '5px' }}>Page
                                        <strong style={{ marginLeft: '5px' }}>
                                            {table.getState().pagination.pageIndex + 1} of {table.getPageCount()}
                                        </strong>
                                    </div>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Bottom />
        </div>
    );
}

export default VenueFavs;

const paginationButtonStyle = {
    backgroundColor: "#32969C",
    color: 'white',
    fontWeight: 'bold',
    fontSize: 15,
    marginRight: '5px',
    fontFamily: 'Raleway'
};

const buttonStyle = {
    backgroundColor: "#32969C",
    color: 'white',
    fontWeight: 'bold',
    fontSize: 15,
    fontFamily: 'Raleway'
};
