import React, { useState } from 'react';
import axios from 'axios';
import ReactGA from "react-ga4";
import logo from '../../images/logo.png';
import { InlineWidget } from "react-calendly";
import NavbarB from './navbar_b.jsx';
import Bottom from '../bottom.jsx';
import { useMediaQuery } from 'react-responsive';

export default function VendorWaitlistB() {
    const [fullName, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [city_region, setCity_region] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [businessName, setBusinessName] = useState('');
    const [businessType, setBusinessType] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [submitted, setSubmitted] = useState(false);

    function joinWaitlist(e) {
        e.preventDefault();

        let request = {
            fullName: fullName,
            email: email,
            phoneNumber: phoneNumber,
            city_region: city_region,
            businessName: businessName,
            businessType: businessType,
            updated_at: Date(),
            unix_timestamp: Date.now()
        }

        console.log(request);

        axios.post('https://l3sf33aj40.execute-api.us-east-1.amazonaws.com/vendor_waitlist', request)
            .then(resp => {
                console.log("Added " + email + " to waitlist:")
                console.log(resp)

                ReactGA.event({
                    category: "Vendor Waitlist Signup",
                    action: "Vendor Waitlist Signup",
                    label: "Vendor Waitlist Signup"
                });

                setSubmitted(true);
            })
            .catch(err => {
                console.log(err);
                setErrorMessage(err.message);
            })
    }

    const isMobile = useMediaQuery({ maxWidth: 984 }); // Define your mobile screen width here

    return (
        <div>
            <NavbarB />

            {isMobile ?
                (
                    <div style={{ height: '100%', width: '100%', paddingTop: '10px',  paddingBottom: '0px', paddingLeft: '10px', paddingRight: '10px'}}>
                        <div>
                            <div className='container' style={{ padding: '10px' }}>
                                <div style={{ visibility: submitted ? 'hidden' : 'visible', height: submitted ? '1px' : 'auto', justifyContent: 'center', alignItems: 'center', textAlign: 'center', margin: '0 auto' }}>
                                    <h1 className='col-mb-3' style={{ fontWeight: 'bold', fontSize: 30 }}>Interested In Partnering With Zenplan? <br></br>Join The Vendor Waitlist Now!</h1>
                                    <h3 className='col-mb-3' style={{ fontSize: 20 }}>Unlock free leads for your wedding vendor business!</h3>
                                    <form onSubmit={(e) => joinWaitlist(e)} style={{ display: 'inline-block', alignItems: 'center', textAlign: 'center', paddingTop: '20px' }}>
                                        <div className="form-group" style={{ paddingBottom: '20px' }}>
                                            <label style={{ fontSize: 16 }}>Full Name</label>
                                            <input style={{ border: '1px solid' }} value={fullName} type="text" id="fullName" className="form-control" placeholder="Enter Full Name" onChange={(event) => setFullName(event.target.value)} required />
                                        </div>

                                        <div className="form-group" style={{ paddingBottom: '20px' }}>
                                            <label style={{ fontSize: 16 }}>Email address</label>
                                            <input style={{ border: '1px solid' }} value={email} type="email" id="userEmail" className="form-control" placeholder="Enter email" required onChange={(event) => setEmail(event.target.value)} />
                                        </div>

                                        <div className='form-group' style={{ paddingBottom: '20px' }}>
                                            <label style={{ fontSize: 16 }}>Phone Number</label>
                                            <input
                                                value={phoneNumber}
                                                style={{ border: '1px solid' }}
                                                type='text'
                                                id='phoneNumber'
                                                name='phoneNumber'
                                                className='form-control'
                                                placeholder='e.g. ###-###-####'
                                                required
                                                onChange={(event) => setPhoneNumber(event.target.value)}
                                            />
                                        </div>

                                        <div className="form-group" style={{ paddingBottom: '20px' }}>
                                            <label style={{ fontSize: 16 }}>What city/region do you operate in?</label>
                                            <input style={{ border: '1px solid' }} value={city_region} type="text" id="city" className="form-control" placeholder="Enter city/region" required onChange={(event) => setCity_region(event.target.value)} />
                                        </div>

                                        <div className="form-group" style={{ paddingBottom: '20px' }}>
                                            <label style={{ fontSize: 16 }}>Business Name</label>
                                            <input style={{ border: '1px solid' }} value={businessName} type="text" id="city" className="form-control" placeholder="Enter business name" required onChange={(event) => setBusinessName(event.target.value)} />
                                        </div>

                                        <div className="form-group" style={{ paddingBottom: '0px' }}>
                                            <label style={{ fontSize: 16 }}>Wedding vendor type (Ex. Venue, Photographer, etc)</label>
                                            <input style={{ border: '1px solid' }} value={businessType} type="text" id="city" className="form-control" placeholder="Enter wedding vendor type" required onChange={(event) => setBusinessType(event.target.value)} />
                                        </div>

                                        <button type="submit" className="btn btn-block" style={buttonStyle}>Join Waitlist</button>
                                    </form>
                                </div>
                                <div style={{ visibility: submitted ? 'visible' : 'hidden', height: submitted ? 'auto' : '1px', justifyContent: 'center', alignItems: 'center', textAlign: 'center', margin: '0 auto' }}>
                                    <h1>You have been added to the vendor waitlist!</h1>
                                    <h3>Please sign up for a free 30 minute call using our Calendly link below, to find out how Zenplan can help you!</h3>

                                    <InlineWidget url="https://calendly.com/zenplan/zenplan-vendor-meet" style={{ visibility: true }} />

                                    <a href='/'><button className="btn btn-block" style={buttonStyle}>Home Page</button></a>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div style={{ height: '100%', width: '100%', paddingTop: '10px',  paddingBottom: '0px', paddingLeft: '50px', paddingRight: '50px'}}>
                        <div>
                            <div className='container' style={{ padding: '10px' }}>
                                <div style={{ visibility: submitted ? 'hidden' : 'visible', height: submitted ? '1px' : 'auto', justifyContent: 'center', alignItems: 'center', textAlign: 'center', margin: '0 auto' }}>
                                    <h1 className='col-mb-3' style={{ fontWeight: 'bold', fontSize: 45 }}>Interested In Partnering With Zenplan? <br></br>Join The Vendor Waitlist Now!</h1>
                                    <h3 className='col-mb-3'>Unlock free leads for your wedding vendor business!</h3>
                                    <form onSubmit={(e) => joinWaitlist(e)} style={{ display: 'inline-block', alignItems: 'center', textAlign: 'center', paddingTop: '30px' }}>
                                        <div className="form-group" style={{ paddingBottom: '15px' }}>
                                            <label style={{ fontSize: 25 }}>Full Name</label>
                                            <input style={{ border: '1px solid' }} value={fullName} type="text" id="fullName" className="form-control" placeholder="Enter Full Name" onChange={(event) => setFullName(event.target.value)} required />
                                        </div>

                                        <div className="form-group" style={{ paddingBottom: '15px' }}>
                                            <label style={{ fontSize: 25 }}>Email address</label>
                                            <input style={{ border: '1px solid' }} value={email} type="email" id="userEmail" className="form-control" placeholder="Enter email" required onChange={(event) => setEmail(event.target.value)} />
                                        </div>

                                        <div className='form-group' style={{ paddingBottom: '15px' }}>
                                            <label style={{ fontSize: 25 }}>Phone Number</label>
                                            <input
                                                value={phoneNumber}
                                                style={{ border: '1px solid' }}
                                                type='text'
                                                id='phoneNumber'
                                                name='phoneNumber'
                                                className='form-control'
                                                placeholder='e.g. ###-###-####'
                                                required
                                                onChange={(event) => setPhoneNumber(event.target.value)}
                                            />
                                        </div>

                                        <div className="form-group" style={{ paddingBottom: '15px' }}>
                                            <label style={{ fontSize: 25 }}>What city/region do you operate in?</label>
                                            <input style={{ border: '1px solid' }} value={city_region} type="text" id="city" className="form-control" placeholder="Enter city/region" required onChange={(event) => setCity_region(event.target.value)} />
                                        </div>

                                        <div className="form-group" style={{ paddingBottom: '15px' }}>
                                            <label style={{ fontSize: 25 }}>Business Name</label>
                                            <input style={{ border: '1px solid' }} value={businessName} type="text" id="city" className="form-control" placeholder="Enter business name" required onChange={(event) => setBusinessName(event.target.value)} />
                                        </div>

                                        <div className="form-group" style={{ paddingBottom: '0px' }}>
                                            <label style={{ fontSize: 25 }}>Wedding vendor type (Ex. Venue, Photographer, etc)</label>
                                            <input style={{ border: '1px solid' }} value={businessType} type="text" id="city" className="form-control" placeholder="Enter wedding vendor type" required onChange={(event) => setBusinessType(event.target.value)} />
                                        </div>

                                        <button type="submit" className="btn btn-block" style={buttonStyle}>Join Waitlist</button>
                                    </form>
                                </div>
                                <div style={{ visibility: submitted ? 'visible' : 'hidden', height: submitted ? 'auto' : '1px', justifyContent: 'center', alignItems: 'center', textAlign: 'center', margin: '0 auto' }}>
                                    <h1>You have been added to the vendor waitlist!</h1>
                                    <h3>Please sign up for a free 30 minute call using our Calendly link below, to find out how Zenplan can help you!</h3>

                                    <InlineWidget url="https://calendly.com/zenplan/zenplan-vendor-meet" style={{ visibility: true }} />

                                    <a href='/'><button className="btn btn-block" style={buttonStyle}>Home Page</button></a>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }

            <Bottom />
        </div>
    );
}

const buttonStyle = {
    marginTop: '50px',
    backgroundColor: "#3FBAC2",
    width: '200px',
    color: 'white',
    fontWeight: 'bold',
    fontSize: 25,
    fontFamily: 'Raleway'
};
