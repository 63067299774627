import React, { useState, useContext, useEffect } from 'react';
import logo from '../images/logo.png';
import showPwdImg from '../images/show-password.svg';
import hidePwdImg from '../images/hide-password.svg';
import UserPool from '../UserPool';
import { CognitoUserAttribute } from 'amazon-cognito-identity-js';
import { useNavigate } from "react-router-dom";
import PasswordChecklist from "react-password-checklist";
import NavbarB from './planning_variant/navbar_b.jsx';
import { AccountContext } from "./Account";
import Bottom from './bottom.jsx';
import ReactGA from "react-ga4";

function Signup() {
    const [errorMessage, setErrorMessage] = useState('');
    const [passConfirmed, setPassConfirmed] = useState(false);
    const [isRevealPwd, setIsRevealPwd] = useState(false);
    const [isConfirmRevealPwd, setIsConfirmRevealPwd] = useState(false);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [city_region, setCity_region] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('+1');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const navigate = useNavigate();

    const { getSession } = useContext(AccountContext);

    useEffect(() => {
        getSession()
            .then(session => {
                console.log("Session: ", session)
                navigate('/profile');
            })
    }, []);

    const validatePhoneNumber = (number) => {
        number = String(number).trim();
        const phoneRegex = /^\+1\d{10}$/; // Ensure the number starts with +1 followed by 10 digits
        return phoneRegex.test(number);
    };

    const signUp = (e) => {
        e.preventDefault();
        let name = new CognitoUserAttribute({ Name: 'given_name', Value: firstName });
        let familyName = new CognitoUserAttribute({ Name: 'family_name', Value: lastName });
        let phone_number = new CognitoUserAttribute({ Name: 'phone_number', Value: phoneNumber });
        let location = new CognitoUserAttribute({ Name: 'address', Value: city_region });

        if (validatePhoneNumber(phone_number.Value)) {
            UserPool.signUp(email, password, [name, familyName, phone_number, location], null, (err, data) => {
                if (err) {
                    console.log(err);
                    setErrorMessage(err.message);
                } else {
                    setErrorMessage('');

                    ReactGA.event({
                        category: "Account Sign Up",
                        action: "Account Sign Up",
                        label: "Account Sign Up"
                    });

                    navigate('/confirm', { state: { email: data.user.username } });
                }  
            });
        } else {
            setErrorMessage("Please fix 'Phone Number' entry");
            console.log("validatePhoneNumber(phone_number)", validatePhoneNumber(phone_number.Value), phone_number)
        }

        
    };

    return (
        <div>
            <NavbarB />
            <div style={{ height: '100%', width: '100%', padding: '50px' }}>
                <div>
                    <div className='container' style={{ padding: '10px' }}>
                        <div style={{ height: 'auto', justifyContent: 'center', alignItems: 'center', textAlign: 'center', margin: '0 auto' }}>
                            <h1 className='col-mb-3' style={{ fontWeight: 'bold', fontSize: 45 }}>Sign Up</h1>
                            <form onSubmit={signUp} style={{ display: 'inline-block', alignItems: 'center', textAlign: 'center', marginTop: '10px' }}>
                                <div className="form-group" style={{ paddingBottom: '10px' }}>
                                    <label style={labelStyle} for="firstName">First Name</label>
                                    <input style={inputStyle} value={firstName} type="text" id="firstName" className="form-control" placeholder="Enter first name" onChange={(event) => setFirstName(event.target.value)} required />
                                </div>

                                <div className="form-group" style={{ paddingBottom: '10px' }}>
                                    <label style={labelStyle} for="lastName">Last Name</label>
                                    <input style={inputStyle} value={lastName} type="text" id="lastName" className="form-control" placeholder="Enter last name" onChange={(event) => setLastName(event.target.value)} required />
                                </div>

                                <div className="form-group" style={{ paddingBottom: '10px' }}>
                                    <label style={labelStyle} for="email">Email address</label>
                                    <input style={inputStyle} value={email} type="email" id="email" className="form-control" placeholder="Enter email" onChange={(event) => setEmail(event.target.value)} required />
                                </div>

                                <div className='form-group' style={{ paddingBottom: '10px' }}>
                                    <label style={labelStyle}>Phone Number</label>
                                    <br></br>
                                    <label style={{ fontSize: 15 }}>(ex. +12065551212)</label>
                                    <input
                                        value={phoneNumber}
                                        type='tel'
                                        maxLength="12"
                                        minLength="12"
                                        id='phoneNumber'
                                        name='phoneNumber'
                                        className='form-control'
                                        placeholder='e.g. +12065551212'
                                        style={inputStyle}
                                        required
                                        onChange={(event) => setPhoneNumber(event.target.value)}
                                    />
                                </div>

                                <div className="form-group" style={{ paddingBottom: '10px' }}>
                                    <label style={labelStyle}>What city/region do you live in?</label>
                                    <input style={inputStyle} value={city_region} type="text" id="city" className="form-control" placeholder="Enter city/region" required onChange={(event) => setCity_region(event.target.value)} />
                                </div>

                                <div className="form-group" style={{ position: 'relative', paddingBottom: '10px' }}>
                                    <label style={labelStyle} for="password">Password</label>
                                    <input value={password} style={{ padding: '5px 30px 5px 10px', border: '1px solid' }} type={isRevealPwd ? "text" : "password"} id="password" className="form-control" placeholder="Enter password" onChange={(event) => setPassword(event.target.value)} required />
                                    <img
                                        style={{ width: '20px', position: 'absolute', right: '1rem', top: '3rem' }}
                                        title={isRevealPwd ? "Hide password" : "Show password"}
                                        src={isRevealPwd ? hidePwdImg : showPwdImg}
                                        onClick={() => setIsRevealPwd(prevState => !prevState)}
                                        alt="Hide/Show password"
                                    />
                                </div>

                                <div className="form-group" style={{ paddingBottom: '10px', position: 'relative' }}>
                                    <label style={labelStyle} for="confirmPassword">Confirm Password</label>
                                    <input value={confirmPassword} style={{ padding: '5px 30px 5px 10px', border: '1px solid' }} type={isConfirmRevealPwd ? "text" : "password"} id="confirmPassword" className="form-control" placeholder="Re-enter password" onChange={(event) => setConfirmPassword(event.target.value)} required />
                                    <img
                                        style={{ width: '20px', position: 'absolute', right: '1rem', top: '3rem' }}
                                        title={isConfirmRevealPwd ? "Hide password" : "Show password"}
                                        src={isConfirmRevealPwd ? hidePwdImg : showPwdImg}
                                        onClick={() => setIsConfirmRevealPwd(prevState => !prevState)}
                                        alt="Hide/Show password"
                                    />
                                    <PasswordChecklist
                                        rules={["minLength", "specialChar", "number", "capital", "lowercase", "match"]}
                                        minLength={8}
                                        value={password}
                                        valueAgain={confirmPassword}
                                        onChange={(isValid) => setPassConfirmed(isValid)}
                                        style={{paddingTop: '10px'}}
                                    />
                                    <div style={{ float: 'left', color: 'red', fontSize: '20px' }}>{errorMessage}</div>
                                </div>

                                <button type="submit" style={buttonStyle} disabled={!passConfirmed} className="btn btn-block">Create Account</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <Bottom />
        </div>
    );
}

export default Signup;

const labelStyle = {
    fontSize: 25
};

const inputStyle = {
    border: '1px solid'
};

const buttonStyle = {
    marginTop: '10px',
    backgroundColor: "#3FBAC2",
    color: 'white',
    fontWeight: 'bold',
    fontSize: 25,
    fontFamily: 'Raleway'
};
