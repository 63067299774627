import './App.css';
import 'bootstrap/dist/css/bootstrap.css';
import Home from "./components/home";
import VendorWaitlistB from "./components/planning_variant/vendor_waitlist_b.jsx"
import UserWaitlistB from './components/planning_variant/user_waitlist_b.jsx'
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ReactGA from "react-ga4";
import Signup from "./components/Signup.js";
import Login from "./components/Login";
import ConfirmSignup from "./components/ConfirmSignup";
import ForgotPassword from "./components/ForgotPassword";
import Profile from "./components/Profile";
import Settings from "./components/Settings";
import { Account } from "./components/Account";
import PhotographerDetail from "./components/PhotographerDetail";
import VenueDetail from "./components/VenueDetail";
import DjDetail from "./components/DjDetail";
import ComingSoon from './components/ComingSoon';
import Venues from './components/Venues';
import Photographers from './components/Photographers';
import Djs from './components/Djs';
import UserFavourites from './components/UserFavourites';
import VenueFavs from './components/VenueFavs';
import PhotoFavs from './components/PhotoFavs';
import DjFavs from './components/DJFavs';

ReactGA.initialize("G-7F0T4X4EK5");

function App() {
  return (
    <Account>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/vendor_waitlist" element={<VendorWaitlistB />} />
          <Route path="/couples_waitlist" element={<UserWaitlistB />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/login" element={<Login />} />
          <Route path="/confirm" element={<ConfirmSignup />} />
          <Route path="/forgot_password" element={<ForgotPassword />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/venues" element={<Venues />} />
          <Route path="/photographers" element={<Photographers />} />
          <Route path="/djs" element={<Djs />} />
          <Route path="/venues/:vendorId" element={<VenueDetail />} />
          <Route path="/photographers/:vendorId" element={<PhotographerDetail />} />
          <Route path="/djs/:vendorId" element={<DjDetail />} />
          <Route path="/comingsoon" element={<ComingSoon />} />
          <Route path="/favourites" element={<UserFavourites />} />
          <Route path="/venue-favourites" element={<VenueFavs />} />
          <Route path="/photo-favourites" element={<PhotoFavs />} />
          <Route path="/dj-favourites" element={<DjFavs />} />
        </Routes>
      </Router>
    </Account>
  );
}

export default App;
