import { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import visionpic from '../../images/visionpic.jpg';
import { useMediaQuery } from 'react-responsive';

export default function About() {
    const isMobile = useMediaQuery({ maxWidth: 984 }); // Define your mobile screen width here

    return (
        <div>
            {isMobile ?
                (
                    <div style={{ paddingTop: '20px', paddingBottom: '40px', paddingLeft: '40px', paddingRight: '40px' }}>
                        <h1 style={{ fontSize: '28px', fontWeight: 'bold', textAlign: 'center', paddingBottom: '10px' }}>
                            Our Vision
                        </h1>
                        <Container>
                            <Row>
                                <Col sm={5}><img style={imgStyle} src={visionpic}></img></Col>
                                <Col sm={7} style={{ marginTop: '15px' }}>
                                    <h5 style={{fontSize: '16px'}}>
                                        Welcome to Zenplan - Your Stress-Free Wedding Planning Companion!
                                        <br></br><br></br>At Zenplan, we’re all about making wedding planning enjoyable, not overwhelming. Founded in 2024 out of personal frustration, Zenplan was created after I experienced the stress of planning my own wedding. The lack of transparent pricing from vendors made what should have been an exciting time into a frustrating and time-consuming process.
                                        <br></br><br></br>Zenplan’s mission is simple: empower couples with the tools they need to plan their dream wedding with ease. We stand out by offering transparent pricing, so you know exactly what to expect—no hidden fees, no surprises.
                                        <br></br><br></br><b>But we’re more than just a pricing tool—and we’re not wedding vendors. Instead, we’re your planning partner, offering intuitive features like vendor comparisons and personalized recommendations to help you make informed decisions effortlessly.</b> Whether you’re just starting out or tackling last-minute details, Zenplan is here to guide you every step of the way. Join us and make your wedding planning journey as joyful as your big day!
                                    </h5>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                ) :
                (
                    <div style={{ padding: '40px' }}>
                        <h1 style={{ fontWeight: 'bold', textAlign: 'center', paddingBottom: '40px' }}>
                            Our Vision
                        </h1>
                        <Container>
                            <Row>
                                <Col sm={4}><img style={imgStyle} src={visionpic}></img></Col>
                                <Col sm={8} style={{ marginTop: '15px' }}>
                                    <h5>
                                    Welcome to Zenplan - Your Stress-Free Wedding Planning Companion!
                                        <br></br><br></br>At Zenplan, we’re all about making wedding planning enjoyable, not overwhelming. Founded in 2024 out of personal frustration, Zenplan was created after I experienced the stress of planning my own wedding. The lack of transparent pricing from vendors made what should have been an exciting time into a frustrating and time-consuming process.
                                        <br></br><br></br>Zenplan’s mission is simple: empower couples with the tools they need to plan their dream wedding with ease. We stand out by offering transparent pricing, so you know exactly what to expect—no hidden fees, no surprises.
                                        <br></br><br></br><b>But we’re more than just a pricing tool—and we’re not wedding vendors. Instead, we’re your planning partner, offering intuitive features like vendor comparisons and personalized recommendations to help you make informed decisions effortlessly.</b> Whether you’re just starting out or tackling last-minute details, Zenplan is here to guide you every step of the way. Join us and make your wedding planning journey as joyful as your big day!
                                    </h5>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                )
            }

        </div>
    );
}

const imgStyle = {
    width: '100%',
    height: '100%',
    boxShadow: '5px 5px 5px 5px #ccc',
    borderRadius: '10px'
};
